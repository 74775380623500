import {Thesaurus} from './thesaurus';
import {SlidesThumbnails} from '../utils/SlidesThumbnails';
import {SlideThumbnail} from '../utils/SlideThumbnail';
import {Info} from '../utils/Info';
import {Card} from '../utils/Card';

export class ThesaurusNL extends Thesaurus {
  label = 'Nederlands';
  //...
  agreed = 'Akkoord';
  brochure = 'brochure';
  brochure_intro = 'In deze brochure vindt u alle informatie over<br>ons E-bike assortiment.';
  cookies = 'Ter verbetering van de functionaliteit van onze website gebruiken wij cookies. Door de website te gebruiken, gaat u akkoord met het gebruik van deze cookies.';
  company = 'OVER SIGMA'; //Bedrijf
  components = 'van de componenten';
  contact = 'Contact';
  contact_person = 'contactpersoon';
  contact_person_b2b = 'Business Contact';
  contact_person_b2c = 'Consumer Contact';
  ebike_ready = new Info(
    'E-Bike ready',
    'De E-Bike ready-serie biedt u en uw klanten dankzij de draadloos verbonden fietscomputer de grootst mogelijke flexibiliteit en talloze extra functies en waarden.'
  );
  electric = '100% ELEKTRISCH - 100% SIGMA';
  eox_headline = 'EOX -';
  eox_series = new Info(
    'Serie',
    'Ergonomisch, innovatief, intelligent - de EOX<sup>&reg;</sup> serie stelt met haar veelzijdige unieke toepassingen de nieuwe standaard voor E-bikes.'
  );
  home_head = [
    new Card(
      'icon-1.png',
      'Onze<br>expertise',
      'Circa 40 jaar ervaring en meer dan 70 miljoen verkochte fietscomputers laten zien dat wij de fietssector en de eisen van de klant heel goed kennen. Deze expertise maakt onze producten uniek en geven ze een lange levensduur.'
    ),
    new Card(
      'icon-2.png',
      'Kwaliteit<br>uit Duitsland',
      'Alle SIGMA producten worden door ons Duitse ingenieursteam in Neustadt an der Weinstraße ontworpen en ontwikkeld. Met ons hoofd&shy;kantoor centraal in Duitsland garanderen we een snelle, flexibele en oplossings&shy;gerichte samenwerking.'
    ),
    new Card(
      'icon-3.png',
      'Samenwerken met een partner',
      'Met ons heeft u een partner aan uw zijde, welke u van idee tot ontwikkeling ondersteunt. Samen werken we aan de beste oplossing voor uw individuele vereisten. Daarnaast bieden we ook een uitmuntende klantenservice, die zich bezig houdt met vragen en problemen.'
    ),
    new Card(
      'icon-4.png',
      'Individuele<br>concepten',
      'Van het eerste productidee tot het eindresultaat ontvangt u van ons een complete oplossing uit één hand. Wij bieden u verschillende setups, zodat u kunt kiezen voor de meest praktische variant.'
    ),
    new Card(
      'icon-5.png',
      'Technologische<br>infrastructuur',
      'Ons technologische fundament omvat meer dan alleen de producthardware. Wij bieden u fiets-specifieke in-house oplossingen op het gebied van app ontwikkeling, cloud-services, navigatie, digitale terreinmodellen en OSM-kaarten aan. Benut nu ook in de toekomst onze know-how voor uw projecten.'
    )
  ];
  home_integrated_app = {
    title: ` APP`,
    desc: 'De EOX<sup>&reg;</sup> app registreert de gereden fietsritten, welke men vanaf elke gewenste plek gemakkelijk kan analyseren en delen. Daarnaast kunnen via de app de op de EOX<sup>&reg;</sup> VIEW 1200 getoonde waarden individueel naar wens worden ingesteld.',
    target: '#rubrik-ebike-3'
  };
  integrated_intro = {
    large: 'Bij de E-Bike Integrated-serie gaat het om vast gemonteerde, bekabelde oplossingen,<br>welke met de E-Bike communiceren.',
    small: 'Bij de E-Bike Integrated-serie gaat het om vast gemonteerde, bekabelde oplossingen, welke met de E-Bike communiceren.'
  };
  integration = 'Integratie';
  language = 'Taal';
  meta = [
    {
      label: 'Impressum',
      href: `https://sigmasport.com/${this.key}/impressum`
    },
    {
      label: 'Privacy Policy',
      href: `https://sigmasport.com/${this.key}/datenschutz`
    }
  ];
  more_info = 'Meer informatie';
  our = 'Onze';
  partners = 'partners';
  pioneer = `SIGMA SPORT is de pionier op het gebied van fietscomputers en staat derhalve voor innovatie, know-how en kwaliteit. Met onze E-Bike-producten geven we vorm aan de toekomst van de E-mobiliteit.`;
  process = [
    'Ontwikkelingsproces',
    'Ontwikkelings-<br>proces'
  ];
  process_steps = {
    integrated: [
      new Card('prozess-icon-int-01.png','1','Componenten<br>selecteren'),
      new Card('prozess-icon-int-02.png','2','Protocollen<br>afstemmen'),
      new Card('prozess-icon-int-03.png','3','Componenten<br>individualiseren')
    ],
    ready: [
      new Card('prozess-icon-01.png','1','Componenten<br>selecteren'),
      new Card('prozess-icon-02.png','2','Protocollen<br>afstemmen'),
      new Card('prozess-icon-03.png','3','Componenten<br>individualiseren')
    ]
  };
  routes = {
    integrated: 'INTEGRATED',
    ready: 'READY'
  };
  rox12 = new Info(
    'ROX 12.0 SPORT',
    'De ROX 12.0 SPORT brengt fietsen met de E-bike naar een nieuw niveau en biedt tegelijkertijd een veelheid aan functies zoals professionele training of innovatieve kaartnavigatie.'
  );
  series = 'Serie';
  services = 'diensten';
  cards_services = [
    new Card(
      'service-teaser-01.png',
      'Gegevensverwerking',
      'De EOX<sup>&reg;</sup> app kan met de data-backend van de E-bike worden verbonden, om specifieke informatie te analyseren, zoals informatie over actieradius, ondersteuningsniveaus of onderhoudsintervallen. Voor de evaluatie en analyse van deze data leggen we graag het contact met onze partners.'
    ),
    new Card(
      'service-teaser-02.png',
      'Individuele aanpassing',
      'Wij kunnen onze E-bike-producten individueel aan uw vereisten aanpassen, zoals het integreren van uw merklogo behuizings&shy;kleuren of layout en selectie van de getoonde waarden van de EOX<sup>&reg;</sup> app. Neem contact met ons op.',
    )
  ];
  setup_comfort = new Info(
    'Setup voor comfort en veelrijders',
    'De EOX<sup>&reg;</sup> REMOTE 500 is via Can-Bus-kabel met het E-bike-systeem en via UART-kabel met de EOX<sup>&reg;</sup> VIEW 1200 verbonden. Het display geeft alle gereden waarden weer, terwijl de EOX<sup>&reg;</sup> app deze via Bluetooth Smart registreert en opslaat.'
  );
  setup_comfort_1300 = new Info(
    'Connectiviteit: twee setup-varianten',
    'De EOX<sup>&reg;</sup> VIEW 1300 is via BLE met de EOX<sup>&reg;</sup> REMOTE 500 verbonden. De afstandsbediening communiceert via CAN-bus met de E-bike. Alternatief kan de EOX<sup>&reg;</sup> VIEW 1300, al naar gelang het systeem, ook direct met een ander component op de E-bike worden verbonden middels BLE of ANT+. Het display toont alle rijgegevens, terwijl de SIGMA EOX<sup>&reg;</sup> app (mits verbonden via BLE) deze ook registreert en opslaat.'
  );
  setup_configurable = new Info(
    'Individueel instelbaar',
    'Met de EOX<sup>&reg;</sup> app kunnen de op de EOX<sup>&reg;</sup> VIEW producten weergegeven waarden individueel worden ingesteld en te allen tijde worden gewijzigd. Zo kan de gebruiker precies die waarden op het display laten weergeven die hij of zij belangrijk vindt.'
  );
  setup_connected = new Info(
    'Always connected',
    'De EOX<sup>&reg;</sup> app heeft portals van derden zoals Strava en Komoot of andere sociale netwerken zoals Facebook en Twitter geïntegreerd. Via de SIGMA CLOUD kunnen te allen tijde activiteiten worden overgedragen en met anderen worden gedeeld.'
  );
  setup_lifestyle = new Info(
    'Setup voor lifestyle, sport & puristen',
    'De EOX<sup>&reg;</sup> REMOTE 500 is via Can-Bus-kabel met het E-bike-systeem verbonden en is voorzien van een Bluetooth Smart-interface. Zo kunt u met de EOX<sup>&reg;</sup> app communiceren, welke de gereden waarden weergeven en registreren.'
  );
  setup_trips = new Info(
    'Setup voor sport<br>en fietsen',
    'De ROX 12.0 SPORT is draadloos met de EOX<sup>&reg;</sup> REMOTE 500 verbonden, welke via de Can-Bus-kabel de fiets&shy;informatie van het E-bike-systeem naar de ROX 12.0 SPORT overdraagt.'
  );

  slides_eox_app = new SlidesThumbnails(
    'Individualiseren, analyseren, delen',
    [
      'De EOX<sup>&reg;</sup> app biedt naast analyse&shy;mogelijkheden ook de mogelijkheid voor het individualiseren. Want via de app kan worden vastgelegd welke rijgegevens op de EOX<sup>&reg;</sup> VIEW producten worden weergegeven. Om ritten te allen tijde en overal te kunnen delen zijn er talrijke interfaces naar social media kanalen.'
    ],
    new SlideThumbnail('app-ueberblick-01.jpg', 'thumb-eox-app-01.jpg', true),
    new SlideThumbnail('app-ueberblick-02.jpg', 'thumb-eox-app-02.jpg'),
    new SlideThumbnail('app-ueberblick-03.jpg', 'thumb-eox-app-03.jpg')
  );
  slides_eox_remote = new SlidesThumbnails(
    'Het intelligente<br>control center',
    ['Met het ergonomische ontwerp, omgevings&shy;lichtsensor en Bluetooth Smart-Integratie is de EOX<sup>&reg;</sup> REMOTE 500 een uniek product in haar klasse. De LED-indicator zorgt ervoor dat alle E-bike informatie ook zonder display zichtbaar is.'],
    new SlideThumbnail(`remote-ueberblick-01-${this.key}.jpg`, 'thumb-remote-01.jpg', true),
    new SlideThumbnail(`remote-ueberblick-02-${this.key}.jpg`, 'thumb-remote-02.jpg'),
    new SlideThumbnail(`remote-ueberblick-03-${this.key}.jpg`, 'thumb-remote-03.jpg'),
    new SlideThumbnail(`remote-ueberblick-04-${this.key}.jpg`, 'thumb-remote-04.jpg'),
    new SlideThumbnail(`remote-ueberblick-05-${this.key}.jpg`, 'thumb-remote-05.jpg')
  );
  slides_functions = new SlidesThumbnails(
    'Design en functie',
    ['De EOX<sup>&reg;</sup> VIEW 1200 overtuigt met trendy lijnen en een dun, compact ontwerp. De sensorgestuurde achtergrondverlichting en het display met hoge resolutie geven bij alle weersomstandigheden en rijsituaties alle data duidelijk leesbaar weer.'],
    new SlideThumbnail('view-ueberblick-01.jpg', 'thumb-view-01.jpg', true),
    new SlideThumbnail('view-ueberblick-02.jpg', 'thumb-view-02.jpg'),
    new SlideThumbnail('view-ueberblick-03.jpg', 'thumb-view-03.jpg'),
    new SlideThumbnail('view-ueberblick-04.jpg', 'thumb-view-04.jpg'),
    new SlideThumbnail('view-ueberblick-05.jpg', 'thumb-view-05.jpg'),
    new SlideThumbnail('view-ueberblick-06.jpg', 'thumb-view-06.jpg'),
  );
  slides_functions_1300 = new SlidesThumbnails(
    'Design en functionaliteit',
    ['De draadloze EOX<sup>&reg;</sup> VIEW 1300 past met haar compacte design goed in het totaalbeeld van de E-bike en zorgt voor een strakke, opgeruimde cockpit. Hij is uitgerust met een batterij met lange levensduur. Daardoor kan deze net als een gewone fietscomputer uit de houder worden gedraaid. De betrouwbare dataverbinding wordt door de ANT+ en Bluetooth interface met hoge prestaties. Met 3 knoppen aan de onderkant kan de E-bike computer makkelijk worden bediend. Het transflectieve FSTN-display zorgt voor de beste leesbaarheid, ook bij direct invallend zonlicht. Een geïntegreerde sensor past de displayverlichting automatisch aan het omgevingslicht aan. '],
    new SlideThumbnail('view-1300-ueberblick-03.jpg', 'thumb-view-1300-03.jpg', true),
    new SlideThumbnail('view-1300-ueberblick-02.jpg', 'thumb-view-1300-02.jpg'),
    new SlideThumbnail('view-1300-ueberblick-01.jpg', 'thumb-view-1300-01.jpg'),
    new SlideThumbnail('view-1300-ueberblick-04.jpg', 'thumb-view-1300-04.jpg'),
    new SlideThumbnail('view-1300-ueberblick-07.jpg', 'thumb-view-1300-07.jpg'),
    new SlideThumbnail('view-1300-ueberblick-05.jpg', 'thumb-view-1300-05.jpg'),
    new SlideThumbnail('view-1300-ueberblick-06.jpg', 'thumb-view-1300-06.jpg')
  );
  slides_led = new SlidesThumbnails(
    'Innovatieve<br>LED-indicator',
    [
      'Dankzij de zeskleurige LED-indicatoren wordt de belangrijkste informatie zoals ondersteunings&shy;niveau, accustatus en foutmelding direct op de EOX REMOTE 500 weergegeven. Dat maakt het gebruik van de Remote gemakkelijk en intuïtief.'
    ],
    new SlideThumbnail('led-indikator-01.jpg', 'BATTERIE&shy;STATUS',true),
    new SlideThumbnail('led-indikator-02.jpg', 'ASSIST MODE'),
    //new SlideThumbnail('led-indikator-03.jpg', 'BLE PAIRING'),
    new SlideThumbnail('led-indikator-04.jpg', 'ERROR MESSAGE')
  );
  slides_positioning = new SlidesThumbnails(
    'Individuele positionering',
    ['De dunne constructie van de EOX<sup>&reg;</sup> VIEW 1200 maakt talrijke houder- en integratiemogelijkheden op de E-bike mogelijk. Het display kan zowel op de voorbouw als op het stuur worden gemonteerd of in fietscomponenten als een stuurpen worden geïntegreerd.'],
    new SlideThumbnail('view-montage-01.jpg', '01',true),
    new SlideThumbnail('view-montage-02.jpg', '02'),
    //new SlideThumbnail('view-montage-03.jpg', '03'),
    new SlideThumbnail('view-montage-04.jpg', '03')
    //new SlideThumbnail('view-montage-05.jpg', '05')
  );
  slides_positioning_1300 = new SlidesThumbnails(
    'Positionering op maat',
    ['Drie verschillende houders maken voor elke E-bike de ideale positionering mogelijk. De OVER STEM BUTLER (Afbeelding 1, Afbeelding 2) voor het monteren direct op de voorbouw van het MTB-stuur. En de RIBBON BUTLER exclusief voor Shimano systemen (Afbeelding 3) biedt met twee posities waaruit men kan kiezen: een draadloos en een geïntegreerd concept voor alle modellen, van stadsfietsen tot racefietsen. Daarnaast bestaat de mogelijkheid de klassieke SIGMA stuurhouder voor draadloze fietscomputers te gebruiken (Afbeelding 4). '],
    new SlideThumbnail('view-1300-montage-01.jpg', '01',true),
    new SlideThumbnail('view-1300-montage-02-explosive.jpg', '02'),
    new SlideThumbnail('view-1300-montage-04.jpg', '03'),
    new SlideThumbnail('view-1300-montage-03.jpg', '04')
  );
  slides_positioning_ribbon = new SlidesThumbnails(
    'All-in-One',
    ['The RIBBON BUTLER can be mounted on the handlebars in a few easy steps. The SHIMANO STEPS EW-EN100 connector unit is then inserted. The Shimano components\' two LEDs and the RIBBON BUTLER\'s button can now be used to switch the e-bike\'s light on and off and enable and display support levels during a ride. The all-in-one solution is ready to create a tidy cockpit.'],
    new SlideThumbnail('ribbon-01.jpg', 'thumb-ribbon-01.jpg', true),
    new SlideThumbnail('ribbon-02.jpg', 'thumb-ribbon-02.jpg')
  );
  slides_rox12 = new SlidesThumbnails(
    'Maximale prestaties',
    ['De ROX 12.0 SPORT is de high-end GPS fietscomputer van SIGMA. Via draadloze interfaces zoals ANT+ LEV of Bluetooth Smart communiceert deze met compatibele E-bike-systemen zoals bijvoorbeeld SHIMANO STEPS. Behalve de weergave en registratie van E-bike-waarden biedt de ROX 12.0 SPORT ook talrijke functies zoals een professioneel trainingssysteem, innovatieve kaartnavigatie en touchdisplay.'],
    new SlideThumbnail(`rox-12-ueberblick-01-${this.key}.jpg`, 'thumb-rox12-01.jpg',true),
    new SlideThumbnail(`rox-12-ueberblick-02-${this.key}.jpg`, 'thumb-rox12-02.jpg'),
    new SlideThumbnail(`rox-12-ueberblick-03-${this.key}.jpg`, 'thumb-rox12-03.jpg'),
    new SlideThumbnail(`rox-12-ueberblick-04-${this.key}.jpg`, 'thumb-rox12-04.jpg'),
    new SlideThumbnail(`rox-12-ueberblick-05-${this.key}.jpg`, 'thumb-rox12-05.jpg')
  );
  socials = [
    {
      slug: 'blog',
      href: 'https://sigma-inmotion.com/'
    },
    {
      slug: 'facebook',
      href: 'https://www.facebook.com/SIGMASPORTBenelux/'
    },
    {
      slug: 'instagram',
      href: 'https://www.instagram.com/sigma_sport_benelux/?hl='+ this.key
    },
    {
      slug: 'youtube',
      href: 'https://www.youtube.com/user/SIGMAelektro'
    },
  ];

  to_product = 'NAAR HET PRODUCT';
  to_rox12 = `Naar de<br>website van<br>ROX 12.0 SPORT`;
  video = 'Productvideo';
  your = 'Uw';
}
