<!--<div class="cookies-container" data-cookie-show="true"> PM (05.07.2019) replaced by the line below-->
<div class="cookies-container" [ngClass]="value">
	<div class="cookies-content">
		<div class="cookie-text">
			<p>
        {{language.theo.cookies}}
        <a href="{{thesaurus().company_url + language.lang}}/datenschutz" target="_blank">&gt; {{language.theo.more_info}}</a>
      </p>
		</div>
		<div class="cookie-button">
			<div class="btn btn-primary website accept-btn">
				<a (click)="hide()">{{language.theo.agreed}}</a>
			</div>
		</div>
	</div>
</div>
