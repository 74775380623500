import {Component, Input} from '@angular/core';
import {Section} from '../../utils/Section';
import * as $ from 'jquery';
import {Thesaurus} from '../../thesauri/thesaurus';

@Component({
    selector: 'section-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.less']
})

/**
 * @see https://codecraft.tv/courses/angular/quickstart/nesting-components-and-inputs/
 */
export class NavComponent extends Section {
  slug = 'nav';
  @Input() route: string;
  routes: string[] = ['integrated', 'ready']


  /**
   * @since PM (10.03.2020) bug fix: quick response to the bootstrap:toggle malfunction
   */
  toggle(){ $('#languages').toggle() }

  set(lang: string, e: Event){
    this.language.set(lang)

    $("div#languages > a."+ this.active).removeClass(this.active)
    this.eltJ(e).addClass(this.active)

    this.toggle()
  }

  thesaurus(){ return Thesaurus }
}
