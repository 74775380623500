<!------------------------------------------- Button trigger modal ------------------------------------------->
<div class="position-fixed contact-btn-position btn-mobile-style">
	<button type="button" class="btn btn-danger btn-lg rounded-0" data-toggle="modal" data-target="#contact_modal_box">
		<img src="{{this.images}}icons/icon-kontakt.png">
	</button>
</div>
<!------------------------------------------- Button trigger modal ------------------------------------------->

<!------------------------------------------- Modal ------------------------------------------->


<!-- PM (25.07.2019) the id ``contact_modal_box`` & the label ``contact_modal_box_label`` were added -->


<div class="modal fade" id="contact_modal_box" tabindex="-1" role="dialog" aria-labelledby="contact_modal_box_label" aria-hidden="true" [ngClass]="language.lang">
	<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
		<div class="modal-content">
			<div class="modal-body">
				<div class="container-fluid">

					<div class="row">
						<div class="col-12">
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true" class="fc-white">&times;</span>
							</button>
						</div>
						<!--
						  @since ML & PM (10.03.2020) @deprecated the PDF files are now available in all languages
						  <div class="col-md-12 fc-white mt-md-2" [ngClass]="language.lang == 'de' ? 'col-lg-6' : 'col-lg-12'">
            -->
						<div class="col-md-12 col-lg-6 fc-white mt-md-2">
							<h4 class="mb-3">{{language.theo.your}} {{language.theo.contact_person}}</h4>
							<h3 class="mb-2 mb-md-3">
                <span class="font-weight-bolder">{{language.theo.contact_person_name}}</span>
                <br>
                <span style="font-weight: 500; font-size: x-large;" [innerHTML]="language.theo.contact_person_title"></span>
              </h3>
			<p class="mb-3">
		  <span class="btn-block"><img  src="{{this.images}}icons/icon-phone.png" class="mr-md-2" alt="E-BIKE">  {{language.theo.contact_person_phone}}</span>
		  <span class="btn-block"><img  src="{{this.images}}icons/icon-mobile.png" class="mr-md-2" alt="E-BIKE" >  {{language.theo.contact_person_mobile}}</span>
		  <a href="mailto:{{language.theo.contact_person_mail}}" class="mb-3 mb-md-2 mb-lg-5 btn-block"><img  src="{{this.images}}icons/icon-mail.png" class="mr-md-2" alt="E-BIKE"> <span class="fc-white"> {{language.theo.contact_person_mail}}</span></a>
						</p>
							<!-- <a href="#rubrik-ebike-7-kontakt" class="btn btn-lg btn-outline-light rounded-0 mb-3 text-uppercase" data-dismiss="modal" [ngClass]="prefix +'scrollable'">{{language.theo.contact}}</a> -->
						</div>
						<div
              *ngIf="language.theo == language.thesauri.de"
              class="col-md-12 col-lg-6 fc-white mt-md-2 mb-md-5 brochure"
            >
							<h4 class="mb-3">{{language.theo.download}} {{language.theo.brochure}}</h4>
							<div class="mb-3"><img src="{{this.images}}modal-download-img.jpg" class="img-fluid"></div>
							<p class="mb-3" [innerHTML]="language.theo.brochure_intro"></p>
							<a href="{{documents}}SIGMA SPORT-E-Bike-Flyer-{{language.lang.toLocaleUpperCase()}}.pdf" class="btn btn-lg btn-outline-light rounded-0 mb-3 text-uppercase" target="_blank">{{language.theo.download}}</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!------------------------------------------- Modal ------------------------------------------->


<!-- /.container -->
  <div class="container">
	<!-- /.container -->

		  <!-- Content Row LINE + RUBRIK ICON -->
  <div id="rubrik-ebike-7-kontakt" class="rubrik-ebike row">
	  <div class="col-md-12 rubrik-wrapper">
		  <!--div class="rubrik-icon">
				  <img  src="images/icons/icon-rubrik-5.svg" alt="E-BIKE">
		  </div-->
		  <div class="rubrik-headline">{{language.theo.your}}<span class="rubrik-headline-color-red"> {{language.theo.contact_person}}</span>
		  </div>
	  </div>
  </div>
  <!-- /.container -->
  </div>
  <!-- /.container -->
  <div class="wrapper-full-size bg-kontakt">
	  <div class="mobile-bg-color h-inherit">
	<!-- /.container -->
  <div class="container" >
	<!-- /.container -->

    <div id="kontakt-info"class="row ml-0 mr-0">
      <ul class="nav nav-tabs pt-4" id="myTab" role="tablist">
        <li class="nav-item">
          <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">{{language.theo.contact_person_b2c}}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">{{language.theo.contact_person_b2b}}</a>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent" style="width: 100%;">
        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
          <div class="row">
              <div class="col-md-6 col-lg-6 mb-2 mt-4 mt-md-5">
                <h3 class="mb-3 mb-md-1 rubrik-headline-color-red">
                  <span class="font-weight-bolder">{{language.theo.contact_person_service_name}}</span>
                </h3>
              </div>
              <div class="col-md-6 col-lg-6mb-5 mt-2 mt-md-5">
                <span class="btn-block rubrik-headline-color-red"><img  src="{{this.images}}icons/icon-phone.png" class="mr-md-2" alt="E-BIKE">  {{language.theo.contact_person_service_phone}}</span><br>
                <a href="mailto:{{language.theo.contact_person_service_mail}}" class="mb-3 mb-md-2 mb-lg-5 btn-block"><img  src="{{this.images}}icons/icon-mail.png" class="mr-md-2" alt="E-BIKE"> <span class="rubrik-headline-color-red"> {{language.theo.contact_person_service_mail}}</span></a>
              </div>
          </div>    
        </div>
        <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
          <div class="row">
            <div class="col-md-6 col-lg-6 mb-2 mt-4 mt-md-5">
              <h3 class="mb-3 mb-md-1 rubrik-headline-color-red">
                <span class="font-weight-bolder">{{language.theo.contact_person_name}}</span>
                <br>
                <span style="font-weight: 500; font-size: x-large;" [innerHTML]="language.theo.contact_person_title"></span>
              </h3>
            </div>
            <div class="col-md-6 col-lg-6mb-5 mt-2 mt-md-5">
              <span class="btn-block rubrik-headline-color-red"><img  src="{{this.images}}icons/icon-phone.png" class="mr-md-2" alt="E-BIKE">  {{language.theo.contact_person_phone}}</span><br>
              <span class="btn-block rubrik-headline-color-red"><img  src="{{this.images}}icons/icon-mobile.png" class="mr-md-2" alt="E-BIKE">  {{language.theo.contact_person_mobile}}</span><br>
              <a href="mailto:{{language.theo.contact_person_mail}}" class="mb-3 mb-md-2 mb-lg-5 btn-block"><img  src="{{this.images}}icons/icon-mail.png" class="mr-md-2" alt="E-BIKE"> <span class="rubrik-headline-color-red"> {{language.theo.contact_person_mail}}</span></a>
            </div>
          </div>
        </div>
      </div>
    </div>

	<!-- /.container -->
  </div>
</div>
	<!-- /.container -->
  </div>
