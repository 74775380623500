import {Component, ViewEncapsulation/*, AfterViewInit*/} from '@angular/core';
import {Page} from '../../utils/Page';
import * as $ from 'jquery';

@Component({
    selector: 'app-root',
    templateUrl: './ready.component.html',
    styleUrls: ['./ready.component.less'],
    encapsulation: ViewEncapsulation.None //prevent encapsulation of css definitions @ee https://angular.io/api/core/ViewEncapsulation
})
export class ReadyComponent extends Page /*implements AfterViewInit*/ {
    slug = 'ready';
    ready = 'active'
}
