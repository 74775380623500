/**
 * PM (04.07.219)
 */
import {Card} from './Card';

export class Slide extends Card {
    active: string;
    constructor(public image: string, public title: string, public desc: string[], active: boolean = false) {
        super(image,title)
        this.desc = desc;
        this.active = active ? 'active' : '';
    }
}
