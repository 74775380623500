<section-cookies [language]="language"></section-cookies>
<section-nav [language]="language" [route]=""></section-nav>
<section-slider [language]="language" [page]="slug"></section-slider>

<!--[BODY]-->
<div class="container">
	<!-- Content Row LINE + RUBRIK ICON -->
	<div id="rubrik-ebike-1" class="rubrik-ebike row">
	    <!-- PM (22.07.2019) @deprecated the symbol doesn't look good
		<div class="col-md-12 mb-1 mb-md-5 rubrik-wrapper">
			<div class="rubrik-headline-icon">
				<img  src="{{this.images}}icons/icon-rubrik-1.png" alt="E-BIKE">
			</div>
		</div>
		-->
	</div>
	<!-- Content Row INTRO -->
	<div class="row">
	      <div class="col-md-12 mb-5 text-lg-center">
			  <h1 class="mb-5 text-uppercase">{{language.theo.electric}}</h1>
			  <p class="intro letter-spacing-bug-fix">{{language.theo.pioneer}}</p>
		</div>
	</div>

	<!-- Content Row -->
	<div class="row">
	   <div class="col-md-12 col-lg-4 mb-5" *ngFor="let card of language.theo.home_head">
		   <div class="card">
			   <div class="h-100 img-box-color">
				   <img  src="{{this.images}}icons/{{card.image}}" alt="E-BIKE">
			   </div>
			   <div class="mt-3">
				   <h5 class="card-title" [innerHTML]="card.title"></h5>
				   <p class="card-text" [innerHTML]="card.desc[0]"></p>
			   </div>
		   </div>
	   </div>
	</div>
	<!-- /.row -->

	<!-- VIDEO Headline -->
	<div id="eox-market-launch" class="rubrik-ebike row">
	    <div class="col-md-12 rubrik-wrapper">
			<div class="rubrik-headline">
        <img class="svg-eox-content-headline-15" src="{{this.images}}eox-logo-rot.svg" data-holder-rendered="true" style="margin-top: -5px;">
        {{language.theo.video}}
      </div>
		</div>
	</div>
	<!-- VIDEO EMBEDED -->
	<div class="row video">
    <ng-template [ngIf]="language.lang == 'de'">
      <iframe
        src="https://www.youtube.com/embed/qo3ulA_3Kj0"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </ng-template>
    <ng-template [ngIf]="language.lang != 'de'">
      <iframe
        src="https://www.youtube.com/embed/_nXUxmx5JR4"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </ng-template>

    <!--
    <video
      preload="metadata" controls="" loop="" muted="" playsinline=""
      src="{{videos}}eox-market-launch-{{language.lang}}.mp4"
      poster="{{images}}eox-market-launch.jpg"
    ></video>
    -->
	</div>

	<!-- Content Row LINE + RUBRIK ICON -->
	<div id="rubrik-ebike-2" class="rubrik-ebike row">
	    <div class="col-md-12 rubrik-wrapper">
			<div class="rubrik-headline">{{language.theo.ebike}} <span class="rubrik-headline-color-red">{{language.theo.integrated}}</span></div>
		</div>
	</div>

	<!-- Content Row INTRO -->
	<div class="row">
	      <div class="col-md-12 mb-5 text-lg-center">
			  <h1 class="mb-5"><img class="svg-eox-content-headline-45" src="{{this.images}}eox-logo-rot.svg" data-holder-rendered="true" style="margin-top: -13px;">{{language.theo.eox_series.title}}</h1>
			  <p class="intro" [innerHTML]="language.theo.eox_series.desc[0]"></p>
		</div>
	</div>
</div>

<div class="wrapper-full-size">
	<div class="row">
		<div class="col-lg-6 mb-5 border-white-r" *ngFor="let entry of language.theo.home_integrated" [ngClass]="entry.classs">
			<h2 class="mb-5 ml-3 ml-md-5 mt-5 fc-white">
				<img  src="{{this.images}}{{entry.image}}" alt="E-BIKE" class="svg-eox-content-headline-35" style="margin-top: -13px;">
				{{entry.title}}
			</h2>
			<a href="./integrated{{entry.target}}" class="ml-5 btn btn-outline-light rounded-0 ml-3 btn-lg position-absolute ab-bt-10 position-r-8 text-uppercase">{{language.theo.to_product}}</a>
		</div>
	</div>
	<div class="container">
		<div class="row mt-5 mb-5" >
			<div class="col-md-8 mb-5 text-lg-center">
				<img  src="{{this.images}}eox-app-startseite-screens.jpg" class="img-fluid" alt="E-BIKE">
			</div>
			<div class="col-md-4 mb-5">
				<h2 class="mb-3 ml-lg-3 mt-lg-5"><img class="svg-eox-content-headline-40" src="{{this.images}}eox-logo-rot.svg" data-holder-rendered="true" style="margin-top: -13px;">{{language.theo.home_integrated_app.title}}</h2>
				<p class="ml-lg-3" [innerHTML]="language.theo.home_integrated_app.desc"></p>
				<a href="./integrated{{language.theo.home_integrated_app.target}}" class="btn btn-outline-danger rounded-0 ml-md-3 btn-lg text-uppercase">{{language.theo.to_product}}</a>
			</div>
		</div>
	</div>
</div>

<div class="container">
	<!-- Content Row LINE + RUBRIK ICON -->
	<div id="rubrik-ebike-3" class="rubrik-ebike row">
	    <div class="col-md-12 rubrik-wrapper">
		    <div class="rubrik-headline">{{language.theo.ebike}} <span class="rubrik-headline-color-red">{{language.theo.ready}}</span></div>
		</div>
	</div>
	<!-- Content Row INTRO -->
	<div class="row">
	      <div class="col-md-12 mb-5 text-lg-center">
			  <h1 class="mb-5">{{language.theo.rox12.title}}</h1>
			  <p class="intro">{{language.theo.rox12.desc[0]}}</p>
		</div>
	</div>
</div>

<div class="wrapper-full-size mb-5 bg-teaser-rox12 ">
	<div class="container h-inherit position-relative">
		<div class="row">
			<div class="col-md-12 mb-5">
				<h2 class="mb-5 ml-3 mt-5 fc-white">{{language.theo.rox12.title}}</h2>
			</div>

		</div>
		<a href="./ready" class="btn btn-outline-light rounded-0 ml-3 btn-lg position-absolute ab-bt-10 btn-start-shadow position-r-8 text-uppercase">{{language.theo.to_product}}</a>
	</div>
</div>

<div class="container mb-5 mt-md-5">
	<!-- Content Row LINE + RUBRIK ICON -->
	<div id="rubrik-ebike-4" class="rubrik-ebike row">
	    <div class="col-md-12 rubrik-wrapper">
			<div class="rubrik-headline">{{language.theo.our}} <span class="rubrik-headline-color-red">{{language.theo.partners}}</span></div>
		</div>
	</div>
	<div class="row mb-5">
		<div class="col-md-4 text-center mb-3" *ngFor="let partner of language.theo.partners_list">
			<img src="{{this.images}}partner/{{partner.image}}" alt="{{partner.title}}" class="img-fluid">
		</div>
	</div>
</div>
<!--[/BODY]-->

<section-contact [language]="language"></section-contact>
<section-footer [language]="language"></section-footer>
