import {Thesaurus} from './thesaurus';
import {SlidesThumbnails} from '../utils/SlidesThumbnails';
import {SlideThumbnail} from '../utils/SlideThumbnail';
import {Info} from '../utils/Info';
import {Card} from '../utils/Card';

export class ThesaurusDE extends Thesaurus {
  label = 'Deutsch';
  //...
  agreed = 'Einverstanden';
  brochure = 'Broschüre';
  brochure_intro = 'In dieser Broschüre finden Sie alle Informationen<br>zu unserem E-Bike-Sortiment.';
  cookies = 'Zur Verbesserung der Funktionalität unserer Seite benutzen wir Cookies. Mit der Nutzung der Seite erklären Sie sich mit der Verwendung der Cookies einverstanden.';
  company = 'Unternehmen';
  components = 'Der Komponenten';
  contact = 'Kontakt';
  contact_person = 'Ansprechpartner';
  contact_person_b2b = 'Kontakt für B2B (OEM) ';
  contact_person_b2c = 'Kontakt für Endkunden';
  ebike_ready = new Info(
    `E-Bike ready`,
    'Die "E-Bike Ready"-Serie bietet Ihnen und Ihren Kunden dank kabellos verbundener Fahrrad Computer größtmögliche Flexibilität und eine Fülle an zusätzlichen Funktionen und Werten.'
  );
  electric = '100% ELEKTRISCH - 100% SIGMA';
  eox_headline = `${this.eox} -`;
  eox_series = new Info(
    '  Serie',
    'Ergonomisch, innovativ, smart - die EOX<sup>&reg;</sup> Serie setzt mit einer Vielzahl einzigartiger Features neue Maßstäbe im Bereich E-Bikes.'
  );
  home_head = [
    new Card(
      'icon-1.png',
      'Unsere<br>Kompetenz',
      'Rund 40 Jahre Erfahrung und über 70.000.000 verkaufte Fahrrad Computer bestätigen, dass wir die Fahrrad&shy;branche und die Kunden&shy;anforderungen im Detail kennen. Diese fachliche Kompetenz macht unsere Produkte einzigartig und langlebig.'
    ),
    new Card(
      'icon-2.png',
      'Qualität<br>aus Deutschland',
      'Alle SIGMA-Produkte werden von unserem Produkt&shy;management-Team in Neustadt an der Weinstraße designt und entwickelt. Mit dem Standort in Deutschland gewährleisten wir eine schnelle, flexible und lösungs&shy;orientierte Zusammenarbeit.'
    ),
    new Card(
      'icon-3.png',
      'Partnerschaftliche<br>Zusammenarbeit',
      'Mit SIGMA SPORT steht Ihnen ein Partner zur Seite, der Sie von der Konzeption bis zur Entwicklung unterstützt. Gemeinsam erarbeiten wir die bestmögliche Lösung für Ihre individuellen Anforderungen. Darüber hinaus bieten wir auch einen exzellenten Kunden&shy;service, der sich um Ihre Fragen und Probleme kümmert.'
    ),
    new Card(
      'icon-4.png',
      'Individuelle<br>Konzepte',
      'Von der ersten Produktidee bis zum fertigen Ergebnis erhalten Sie von uns eine Komplett&shy;lösung aus einer Hand. Wir bieten Ihnen verschiedene Setups, sodass Sie die für Sie beste Variante auswählen können.'
    ),
    new Card(
      'icon-5.png',
      'Technologische<br>Infrastruktur',
      'Unser technologisches Fundament geht über die reine Produkt-Hardware hinaus. Wir bieten Ihnen fahrrad&shy;spezifische In-House-Lösungen für die Bereiche App-Development, Cloud Services, Navigation, digitale Geländemodelle und OSM-Karten an. Nutzen Sie zukünftig unser Know-how auch für Ihre Projekte!'
    )
  ];
  home_integrated_app = {
    title: ` APP`,
    desc: 'Die EOX<sup>&reg;</sup> App zeichnet die gefahrenen Strecken auf, die man von überall aus bequem analysieren und teilen kann. Darüber hinaus können über die App sowohl die EOX<sup>&reg;</sup> REMOTE 500, als auch die EOX<sup>&reg;</sup> VIEW Produkte indiviudell konfiguriert werden.',
    target: '#rubrik-ebike-3'
  };
  integrated_intro = {
    large: 'Bei der "E-Bike Integrated"-Serie handelt es sich um fest verbaute, kabelgebundene Lösungen,<br>die mit dem E-Bike kommunizieren.',
    small: 'Bei der "E-Bike Integrated"-Serie handelt es sich um fest verbaute, kabelgebundene Lösungen, die mit dem E-Bike kommunizieren.'
  };
  integration = 'Integration';
  language = 'Sprache';
  meta = [
    {
      label: 'Impressum',
      href: `https://sigmasport.com/${this.key}/impressum`
    },
    {
      label: 'Datenschutz',
      href: `https://sigmasport.com/${this.key}/datenschutz`
    }
  ];
  more_info = 'Mehr Infos';
  our = 'unsere';
  partners = 'Partner';
  pioneer = `SIGMA SPORT ist DER Pionier in der Entwicklung von Fahrrad Computern und steht seit jeher für Innovation, Know-how und Qualität. Mit unseren E-Bike-Produkten gestalten wir die Zukunft der E-Mobilität neu.`;
  process = [
    'Entwicklungsprozess',
    'Entwicklungs-<br>prozess'
  ];
  process_steps = {
    integrated: [
      new Card('prozess-icon-int-01.png','1','Komponenten<br>wählen'),
      new Card('prozess-icon-int-02.png','2','Protokolle<br>abstimmen'),
      new Card('prozess-icon-int-03.png','3','Komponenten<br>individualisieren')
    ],
    ready: [
      new Card('prozess-icon-01.png','1','Komponenten<br>wählen'),
      new Card('prozess-icon-02.png','2','Protokolle<br>abstimmen'),
      new Card('prozess-icon-03.png','3','Komponenten<br>individualisieren')
    ]
  };
  routes = {
    integrated: 'INTEGRATED',
    ready: 'READY'
  };
  rox12 = new Info(
    'ROX 12.0 SPORT',
    'Der ROX 12.0 SPORT hebt Fahrten mit dem E-Bike auf ein neues Level und bietet gleichzeitig eine Fülle an Funktionen wie professionelles Training oder innovative Kartennavigation.'
  );
  series = 'Serie';
  cards_services = [
    new Card(
      'service-teaser-01.png',
      'Datenverarbeitung',
      'Die EOX<sup>&reg;</sup> App kann die aufgezeichneten Daten dem Daten-Backend ihrer Wahl zur Verfügung stellen. Hier können detaillierte Systeminformationen analysiert und weiterverarbeitet werden. Falls noch kein Daten-Backend vorhanden ist, stellen wir gerne den Kontakt zu zuverlässigen Anbietern her.'
    ),
    new Card(
      'service-teaser-02.png',
      'Individuelle Anpassung',
      'Wir können unsere E-Bike-Produkte individuell an Ihre Anforderungen anpassen, sei es die Integration Ihres Marken-Logos, Gehäusefarben oder Ansicht und Auswahl der angezeigten Werte der EOX<sup>&reg;</sup> App. Sprechen Sie uns an!'
    )
  ];
  setup_comfort = new Info(
    'Setup für Komfort & Vielfahrer',
    'Die EOX<sup>&reg;</sup> REMOTE 500 ist per Can Bus mit dem E-Bike-System und über ein UART-Kabel mit dem EOX<sup>&reg;</sup> VIEW 1200 verbunden. Das Display zeigt alle Fahrdaten an, während die EOX<sup>&reg;</sup> App diese über Bluetooth Smart aufzeichnet und abspeichert.'
  );
  setup_comfort_1300 = new Info(
    'Connectivity: Zwei Setup-Varianten',
    'Der EOX<sup>&reg;</sup> VIEW 1300 ist via BLE mit der EOX<sup>&reg;</sup> REMOTE 500 verbunden. Die Remote kommuniziert über CAN-Bus mit dem E-Bike. Alternativ kann der EOX<sup>&reg;</sup> VIEW 1300, je nach System, auch direkt mit einer anderen Komponente am E-Bike per BLE oder ANT+ verbunden werden. <br><br>Das Display zeigt alle Fahrdaten an, während die SIGMA EOX<sup>&reg;</sup> App (sofern über BLE verbunden) diese auch aufzeichnet und abspeichert.'
  );
  setup_configurable = new Info(
    'Individuell konfigurierbar',
    'Mit der EOX<sup>&reg;</sup> App können die auf dem EOX<sup>&reg;</sup> VIEW Produkten angezeigten Werte individuell ausgewählt und jederzeit geändert werden. So kann sich der Nutzer genau jene Werte auf dem Display anzeigen lassen, die er benötigt.'
  );
  setup_connected = new Info(
    'Always connected',
    'Die aufgezeichneten Fahrten können in die kostenlose SIGMA CLOUD hochgeladen werden. Über Dritt&shy;anbieterportale wie Strava, komoot, Facebook, Instagram, Twitter und Whatsapp können die Aktivitäten jederzeit mit anderen geteilt werden.'
  );
  setup_lifestyle = new Info(
    'Setup für Lifestyle, Sport & Puristen',
    'Die EOX<sup>&reg;</sup> REMOTE 500 ist per Can Bus mit dem E-Bike-System verbunden und besitzt eine Bluetooth Smart-Schnittstelle. So kann sie mit der EOX<sup>&reg;</sup> App kommunizieren, die zur Anzeige, Aufzeichnung und Analyse der Fahrdaten verwendet werden kann.'
  );
  setup_trips = new Info(
    'Setup für Sport & <br>Radreisen',
    'Der ROX 12.0 SPORT ist per Bluetooth Smart mit der EOX<sup>&reg;</sup> REMOTE 500 verbunden, die über das Can Bus die Fahrrad&shy;informationen vom E-Bike-System an den ROX 12.0 SPORT übermittelt.'
  );

  slides_eox_app = new SlidesThumbnails(
    'Individualisieren, analysieren, teilen',
    [
      'Die EOX<sup>&reg;</sup> App bietet neben Analyse&shy;möglichkeiten auch die Möglichkeit zur Individualisierung. Denn über die App kann festgelegt werden, welche Fahrdaten auf den EOX<sup>&reg;</sup> VIEW Produkten angezeigt werden. Um Fahrten jederzeit und überall zu teilen, gibt es zahlreiche Schnittstellen zu Social Media-Kanälen.'
    ],
    new SlideThumbnail('app-ueberblick-01.jpg', 'thumb-eox-app-01.jpg', true),
    new SlideThumbnail('app-ueberblick-02.jpg', 'thumb-eox-app-02.jpg'),
    new SlideThumbnail('app-ueberblick-03.jpg', 'thumb-eox-app-03.jpg')
  );
  slides_eox_remote = new SlidesThumbnails(
    'Die smarte<br>Schaltzentrale',
    ['Mit ergonomischem Design, Umgebungs&shy;lichtsensor und Bluetooth Smart-Integration ist die EOX<sup>&reg;</sup> REMOTE 500 ein einzigartiges Produkt in Ihrer Klasse. Der LED-Indikator macht die wichtigsten E-Bike Informationen auch ohne Display sichtbar.'],
    new SlideThumbnail(`remote-ueberblick-01-${this.key}.jpg`, 'thumb-remote-01.jpg', true),
    new SlideThumbnail(`remote-ueberblick-02-${this.key}.jpg`, 'thumb-remote-02.jpg'),
    new SlideThumbnail(`remote-ueberblick-03-${this.key}.jpg`, 'thumb-remote-03.jpg'),
    new SlideThumbnail(`remote-ueberblick-04-${this.key}.jpg`, 'thumb-remote-04.jpg'),
    new SlideThumbnail(`remote-ueberblick-05-${this.key}.jpg`, 'thumb-remote-05.jpg')
  );
  slides_functions = new SlidesThumbnails(
    'Ästhetik und Funktion',
    ['Der EOX<sup>&reg;</sup> VIEW 1200 überzeugt mit klaren Linien und flachem Design. Die sensorgesteuerte Hintergrundbeleuchtung und das transflektive Display zeigen bei allen Wetterlagen und Fahrsituationen sämtliche Daten klar lesbar an.'],
    new SlideThumbnail('view-ueberblick-01.jpg', 'thumb-view-01.jpg', true),
    new SlideThumbnail('view-ueberblick-02.jpg', 'thumb-view-02.jpg'),
    new SlideThumbnail('view-ueberblick-03.jpg', 'thumb-view-03.jpg'),
    new SlideThumbnail('view-ueberblick-04.jpg', 'thumb-view-04.jpg'),
    new SlideThumbnail('view-ueberblick-05.jpg', 'thumb-view-05.jpg'),
    new SlideThumbnail('view-ueberblick-06.jpg', 'thumb-view-06.jpg'),
  );
  slides_functions_1300 = new SlidesThumbnails(
    'Design und Funktionalität',
    ['Der kabellose EOX<sup>&reg;</sup> VIEW 1300 fügt sich mit seinem flachen Design dezent ins Gesamtbild des E-Bikes ein und sorgt für einen aufgeräumtes Cockpit. Er ist mit einer langlebigen Batterie ausgestattet. Dadurch lässt er sich wie ein regulärer Fahrrad Computer aus der Halterung drehen. Die sichere Datenverbindung wird durch die hoch performante ANT+ und Bluetooth-Schnittstelle gewährleistet. Mit 3 Tasten an der Unterseite ist der E-Bike Computer leicht zu bedienen. Das transflektive FSTN Display sorgt für beste Lesbarkeit auch bei direkter Sonneneinstrahlung. Ein integrierter Sensor passt die Displaybeleuchtung automatisch ans Umgebungslicht an.'],
    new SlideThumbnail('view-1300-ueberblick-03.jpg', 'thumb-view-1300-03.jpg', true),
    new SlideThumbnail('view-1300-ueberblick-02.jpg', 'thumb-view-1300-02.jpg'),
    new SlideThumbnail('view-1300-ueberblick-01.jpg', 'thumb-view-1300-01.jpg'),
    new SlideThumbnail('view-1300-ueberblick-04.jpg', 'thumb-view-1300-04.jpg'),
    new SlideThumbnail('view-1300-ueberblick-07.jpg', 'thumb-view-1300-07.jpg'),
    new SlideThumbnail('view-1300-ueberblick-05.jpg', 'thumb-view-1300-05.jpg'),
    new SlideThumbnail('view-1300-ueberblick-06.jpg', 'thumb-view-1300-06.jpg')

  );
  slides_led = new SlidesThumbnails(
    'Innovativer <br>LED-Indikator',
    [
      'Dank des LED-Indikators werden die wichtigsten Informationen wie Unterstützungs&shy;stufe, Batteriestatus und Error Message direkt an der EOX<sup>&reg;</sup> REMOTE 500 angezeigt. So wird die Nutzung der EOX<sup>&reg;</sup> REMOTE 500 einfach und selbsterklärend.'
    ],
    new SlideThumbnail('led-indikator-01.jpg', 'BATTERIE&shy;STATUS',true),
    new SlideThumbnail('led-indikator-02.jpg', 'ASSIST MODE'),
    //new SlideThumbnail('led-indikator-03.jpg', 'BLE PAIRING'),
    new SlideThumbnail('led-indikator-04.jpg', 'ERROR MESSAGE')
  );
  slides_positioning = new SlidesThumbnails(
    'Individuelle Positionierung',
    ['Die flache Bauweise des EOX<sup>&reg;</sup> VIEW 1200 erlaubt zahlreiche Halterungs- und Integrations&shy;möglichkeiten am E-Bike. Das Display kann sowohl am Vorbau als auch am Lenker montiert oder in Fahrradkomponenten integriert werden.'],
    new SlideThumbnail('view-montage-01.jpg', '01',true),
    new SlideThumbnail('view-montage-02.jpg', '02'),
    //new SlideThumbnail('view-montage-03.jpg', '03'),
    new SlideThumbnail('view-montage-04.jpg', '03')
    //new SlideThumbnail('view-montage-05.jpg', '05')
  );
  slides_positioning_1300 = new SlidesThumbnails(
    'Vielfache Montagemöglichkeiten',
    ['Der EOX<sup>&reg;</sup> VIEW 1300 kann mithilfe verschiedener Halterungen ideal auf jedem E-Bike Typ positioniert werden. Der OVER CLAMP BUTLER (Bild 1, Bild 2)  eignet sich für die Anbringung direkt über dem Vorbau des MTB-Lenkers. Die klassische SIGMA STS-Lenkerhalterung (Bild 3) kann ebenfalls für die Montage des EOX<sup>&reg;</sup> VIEW 1300 genutzt werden. Mit dem EOX<sup>&reg;</sup> RIBBON BUTLER (Bild 4) wurde zudem ein einzigartiges Haltersystem speziell für SHIMANO STEPS entwickelt. '],
    new SlideThumbnail('view-1300-montage-01.jpg', '01',true),
    new SlideThumbnail('view-1300-montage-02-explosive.jpg', '02'),
    new SlideThumbnail('view-1300-montage-04.jpg', '03'),
    new SlideThumbnail('view-1300-montage-03.jpg', '04')
  );
  slides_positioning_ribbon = new SlidesThumbnails(
    'All-in-One Lösung',
    ['Der EOX<sup>&reg;</sup> RIBBON BUTLER wurde  exklusiv für das SHIMANO STEPS System entwickelt. Er bietet mit zwei Positionen zur Wahl ein kabelloses und integratives Konzept für alle E-Bike-Modelle von City bis Rennrad. Die Verbindung ist denkbar einfach: Der EOX<sup>&reg;</sup> RIBBON BUTLER lässt sich mit wenigen Handgriffen am Fahrradlenker montieren. Anschließend wird die SHIMANO STEPS Verbindungseinheit EW-EN100 eingesetzt. Über die zwei LEDs der Shimano-Komponente und die Taste des EOX<sup>&reg;</sup> RIBBON BUTLER kann nun während der Fahrt das Licht am E-Bike an- und ausgeschaltet sowie die Unterstützungsstufen aktiviert und angezeigt werden. Fertig ist die All-in-One Lösung für ein aufgeräumtes Cockpit.'],
    new SlideThumbnail('ribbon-01.jpg', 'thumb-ribbon-01.jpg', true),
    new SlideThumbnail('ribbon-02.jpg', 'thumb-ribbon-02.jpg')
  );
  slides_rox12 = new SlidesThumbnails(
    'Maximale Performance',
    ['Der ROX 12.0 SPORT ist der Highend-GPS-Fahrrad Computer von SIGMA SPORT. Über drahtlose Schnittstellen wie ANT+ LEV oder Bluetooth Smart kommuniziert er mit kompatiblen E-Bike-Systemen wie beispielsweise SHIMANO STEPS. Neben der Anzeige und Aufzeichnung von E-Bike-Werten bietet der ROX 12.0 SPORT auch klassische Funktionen wie ein professionelles Trainingssystem, innovative Kartennavigation und ein Touchdisplay.'],
    new SlideThumbnail(`rox-12-ueberblick-01-${this.key}.jpg`, 'thumb-rox12-01.jpg',true),
    new SlideThumbnail(`rox-12-ueberblick-02-${this.key}.jpg`, 'thumb-rox12-02.jpg'),
    new SlideThumbnail(`rox-12-ueberblick-03-${this.key}.jpg`, 'thumb-rox12-03.jpg'),
    new SlideThumbnail(`rox-12-ueberblick-04-${this.key}.jpg`, 'thumb-rox12-04.jpg'),
    new SlideThumbnail(`rox-12-ueberblick-05-${this.key}.jpg`, 'thumb-rox12-05.jpg')
  );

  to_product = 'ZUM PRODUKT';
  to_rox12 = `Zur<br>ROX 12.0 SPORT<br>website`;
  video = 'Produktvideo';
  your = 'Ihr';
}
