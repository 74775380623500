/**
 * PM (23.07.219)
 */
import {Info} from './Info';

export class Card extends Info {
    constructor(public image: string, public title: string, ...desc: string[]){
        super(title);
        this.desc = desc;
    }
}
