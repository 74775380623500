<section-cookies [language]="language"></section-cookies>
<section-nav [language]="language" [route]="slug"></section-nav>


<!--[BODY]-->
 <!-------------------------------------------- HEADER SLIDER ------------------------------------------->
 <div class="wrapper-full-size mb-5">
	
	
	<div id="carouselExampleCaptions" class="carousel slide" data-ride="carousel" data-interval="false">
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img class="d-block w-100" src="{{this.images}}e-bike-header-02-big-{{this.language.lang}}.jpg" data-holder-rendered="true">
      </div>
    </div>
  </div>
	
	
</div>

<!-- /.container -->
<div class="container">
<!-- /.container -->
<!-- Content Row INTRO -->
<div class="row">
      <div class="col-md-12 mb-5 text-lg-center">
		  <h1 class="mb-5 text-uppercase">{{language.theo.ebike_ready.title}}</h1>
		  <p class="intro">{{language.theo.ebike_ready.desc[0]}}</p>
	</div>
</div>
<!-- Content Row LINE + RUBRIK ICON -->
<div id="rubrik-ebike-1" class="rubrik-ebike row">
    <div class="col-md-12 mb-3 rubrik-wrapper">
		<div class="rubrik-headline">ROX 12.0 SPORT</div>
	</div>
</div>

<!-- Content Row INTRO -->
<div class="row">
      <div class="col-md-12 mb-3 text-lg-center">
		  <h2 class="mb-3">ROX 12.0 SPORT</h2>
	</div>
</div>
  <!-- /.container -->
<div class="row mb-5">
	<div class="col-md-6 mb-5">
		
		
		<!--Carousel Wrapper-->
<div id="carousel-thumb" class="carousel slide carousel-fade carousel-thumbnails" data-ride="carousel" data-interval="false">
    <!--Slides-->
    <div class="carousel-inner" role="listbox">
	    <div class="carousel-item" *ngFor="let slide of language.theo.slides_rox12.slides" [ngClass]="slide.active">
		    <img class="d-block " src="{{this.images + slide.image}}"  class="img-fluid" alt="ROX 12.0 SPORT ">
	    </div>
    </div>
    <!--/.Slides-->
	<!--TODO language-->
	<a [href]="'https://www.sigmasport.com/'+ language.lang +'/produkte/fahrrad-computer/gps/rox/rox-12-0'" class="btn btn-outline-danger ml-5 rounded-0 rox12-link-extern text-uppercase" target="_blank" style="font-size: 0.90rem;" [innerHTML]="language.theo.to_rox12"></a>
</div>
<!--/.Carousel Wrapper-->
	
	
	
	
	
	</div>
	<div class="col-md-6 mb-5">
		<div class="mt-lg-5">
			<h3 class="mb-3"><span >{{language.theo.slides_rox12.title}}</span></h3>
			<p class="mb-5">{{language.theo.slides_rox12.desc[0]}}</p>
			
		</div>
		
		<div class="d-block w-100">
			<ol class="carousel-indicators carousel-indicators-sigma">
				<li data-target="#carousel-thumb" data-slide-to="0" *ngFor="let slide of language.theo.slides_rox12.slides; let i = index" [ngClass]="slide.active" [attr.data-slide-to]="i">
					<img class="d-block w-100" src="{{this.images + slide.thumbnail}}" class="img-fluid">
				</li>
			</ol>
		</div>
		
	</div>
</div>



<div class="row">
	<div class="col-md-12 col-lg-6 mb-5 ">
		<h3 class="mb-3 ml-md-5 mt-md-5"><span [innerHTML]="language.theo.setup_trips.title"></span></h3>
		<p class="ml-md-5 mr-md-5" [innerHTML]="language.theo.setup_trips.desc[0]"></p>
		<!-- PM (06.08.2019) @deprecated &shy; is now used
    <p class="ml-md-5 d-none d-sm-block" [innerHTML]="language.theo.setup_trips.desc[0]"></p>
		<p class="ml-md-5 d-sm-none">{{language.theo.setup_trips.desc[1]}}</p>
		-->
	</div>
	<div class="col-md-12 col-lg-6 mb-5">
		<img src="{{this.images}}rox-12-setup.png"  class="img-fluid">
	</div>
</div>
	
	
	<!--<section-process [language]="language" [page]="slug"></section-process>-->

<!-- /.container -->
</div>
<!-- /.container -->

<!--[/BODY]-->

<section-contact [language]="language"></section-contact>
<section-footer [language]="language"></section-footer>
