import {Injectable, OnInit} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {Thesaurus} from '../thesauri/thesaurus';
import {ThesaurusDE} from '../thesauri/thesaurusDE';
import {ThesaurusEN} from '../thesauri/thesaurusEN';
import {ThesaurusNL} from '../thesauri/thesaurusNL';
import {Title} from '@angular/platform-browser';

@Injectable({
    providedIn: 'root',
})
export class LanguageService /*implements OnInit*/ {
    private readonly key = 'language';
    private readonly default = 'en';
    readonly thesauri = {
        de: new ThesaurusDE('de'), //PM (24.07.2019)
        en: new ThesaurusEN('en'), //PM (xx.07.2019)
        nl: new ThesaurusNL('nl'), //PM (31.07.2019)

        /** @todo add the other thesauri here */
    }
    readonly langs = Object.keys(this.thesauri)
    theo: Thesaurus;
    lang: string;


    /**
     * this function is used to initialise the language & thesaurus
     * @see https://stackoverflow.com/questions/36914151/browser-language-detection?noredirect=1&lq=1 # navigator.language
     */
    constructor(private cookieService: CookieService, private titleService: Title){} //DO NOT set the language here, it is too early; the page therefore takes care of it on init


    /* alternative
    ngOnInit(): void {
        //this.set(this.cookieService.get(this.key) || navigator.language || this.default);
    }
    */

    /**
     * this function is used to get the list of the keys of all the languages available
     * @return string[]
     */
    list(): string[] { return Object.keys(this.thesauri) }

    /**
     * this function is used to set/update the language & thesaurus
     * @param lang the key of the new language
     * @return Thesaurus the currently active
     */
    set(lang: string = this.default): void {
        //console.log(navigator.language.substr(0, 2))
        if(this.lang) {
            //DO NOT MERGE
            if (lang in this.thesauri) this.setIn(lang)
        }else{ //on first invocation during the loading process
            lang = this.cookieService.get(this.key) || navigator.language.substr(0, 2)
            if(!(lang in this.thesauri)) lang = this.default

            this.setIn(lang)
        }
    }


    private setIn(lang: string){
        this.cookieService.set(this.key, lang); //buffer the selection
        this.theo = this.thesauri[lang];
        this.lang = lang

        this.titleService.setTitle(this.theo.title())
    }
}
