<section-cookies [language]="language"></section-cookies>
<section-nav [language]="language" [route]="slug"></section-nav>

<!--[BODY]-->
 <!-------------------------------------------- HEADER SLIDER ------------------------------------------->
 <div class="wrapper-full-size mb-5">
	<div id="carouselExampleCaptions" class="carousel slide" data-ride="carousel" data-interval="false">
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img class="d-block w-100" src="{{this.images}}e-bike-header-01.jpg" data-holder-rendered="true">
      </div>
    </div>
  </div>
</div>


<!-- /.container -->
<div class="container">
	<!-- /.container -->
	<!-- Content Row INTRO -->
	<div class="row">
	      <div class="col-md-12 mb-5 text-lg-center">
			  <h1 class="mb-5 text-uppercase">{{language.theo.ebike}} {{language.theo.integrated}}</h1>
			  <p class="intro d-none d-lg-block" [innerHTML]="language.theo.integrated_intro.large"></p>
			  <p class="intro d-lg-none">{{language.theo.integrated_intro.small}}</p>
		</div>
	</div>
	<!-- Content Row LINE + RUBRIK ICON -->
	<div id="rubrik-ebike-1" class="rubrik-ebike row">
	    <div class="col-md-12 mb-3 rubrik-wrapper">
			<div class="rubrik-headline"><img class="svg-eox-content-headline-15" src="{{this.images}}eox-logo-rot.svg" data-holder-rendered="true" style="margin-top: -5px;"> {{language.theo.series}}</div>
		</div>
	</div>
	<!-- Content Row INTRO -->
	<div class="row">
	      <div class="col-md-12 mb-3 text-lg-center">
			  <h2 class="mb-3"><img class="svg-eox-content-headline-40" src="{{this.images}}eox-logo-rot.svg" data-holder-rendered="true" style="margin-top: -13px;"> {{language.theo.remote}}</h2>
		</div>
	</div>

	<div class="row mb-5">
		<div class="col-lg-7 col-md-12 mb-md-5 ">
			<!--Carousel Wrapper-->
			<div id="carousel-thumb99" class="carousel-sigma-ebike carousel slide carousel-fade carousel-thumbnails" data-ride="carousel" data-interval="false">
				<!--Slides-->
				<div class="carousel-inner" role="listbox">
					<div class="carousel-item text-center" *ngFor="let slide of language.theo.slides_eox_remote.slides" [ngClass]="slide.active">
						<img class="text-center" src="{{this.images + slide.image}}" class="img-fluid" alt="EOX REMOTE ">
					</div>
				</div>
				<!--/.Slides-->
			</div>
		</div>
		<div class="col-lg-5 col-md-12 mt-md-5">
				<h3 class="mb-3 ml-md-5"><span  [innerHTML]="language.theo.slides_eox_remote.title"></span></h3>
				<p class="ml-md-5" [innerHTML]="language.theo.slides_eox_remote.desc[0]"></p>

			<div class="d-block w-100 ml-md-5">
					<ol class="carousel-indicators carousel-indicators-sigma">
						<li data-target="#carousel-thumb99" *ngFor="let slide of language.theo.slides_eox_remote.slides; let i = index" [ngClass]="slide.active" [attr.data-slide-to]="i">
							<img class="d-block w-100" src="{{this.images + slide.thumbnail}}" class="img-fluid">
						</li>
					</ol>
				</div>
		</div>

	</div>



<!-- /.container -->
</div>
<!-- /.container -->

<div id="focous-led" class="wrapper-full-size mb-5">
	<div id="carouselLEDCaptions" class="carousel slide carousel-fade" data-ride="carousel" data-interval="false">
		<ol class="carousel-indicators">
			<li data-target="#carouselLEDCaptions" class="col-6 fc-white mb-2 mr-2" *ngFor="let slide of language.theo.slides_led.slides; let i = index" [ngClass]="slide.active" [attr.data-slide-to]="i">
				<p class="mt-1 mb-3 text-uppercase" [innerHTML]="slide.thumbnail"></p>
			</li>
		</ol>
		<div class="carousel-inner">

			<div class="carousel-caption-black-half bg-black-content-half-r h-inherit ">
				<div class="container">
					<div class="row">
						<div class="col-lg-6 mt-5 fc-white">
								<h3 class="mb-3 ml-md-5"><span  [innerHTML]="language.theo.slides_led.title"></span></h3>
								<p class="ml-md-5 mr-md-5" [innerHTML]="language.theo.slides_led.desc[0]"></p>
                <!-- PM (06.08.2019) @deprecated &shy; is used now
								<p class="ml-md-5 d-none d-lg-block" [innerHTML]="language.theo.slides_led.desc[0]"></p>
								<p class="ml-md-5 d-lg-none">{{language.theo.slides_led.desc[1]}}</p>
                -->
							</div>
					</div>
				</div>
			</div>


			<div class="carousel-item" *ngFor="let slide of language.theo.slides_led.slides" [ngClass]="slide.active">
				<img class="d-block w-100" src="{{this.images + slide.image}}" data-holder-rendered="true">
			</div>
		</div>
    </div>
</div>

<div class="container">
	<div class="row">
		<div class="col-lg-7 col-md-6 mb-5 mt-md-5">
			<img src="{{this.images}}remote-setup.png" class="img-fluid" alt="E-BIKE">
		</div>
		<div class="col-lg-5 col-md-6 mb-5 mt-md-5">
			<h3 class="mb-3 ml-md-4 mt-md-5 "><span >{{language.theo.setup_lifestyle.title}}</span></h3>
			<p class="ml-md-4" [innerHTML]="language.theo.setup_lifestyle.desc[0]"></p>
      <!-- PM (06.08.2019) @deprecated &shy; s now used
			<p class="ml-md-5 d-none d-lg-block" [innerHTML]="language.theo.setup_lifestyle.desc[0]"></p>
			<p class="ml-md-5 d-lg-none">{{language.theo.setup_lifestyle.desc[1]}}</p>
      -->
		</div>
	</div>


	<!-- Content Row LINE + RUBRIK ICON -->
	<div id="rubrik-ebike-2" class="rubrik-ebike row">
	    <div class="col-md-12 mb-3 rubrik-wrapper">
			<div class="rubrik-headline"><img class="svg-eox-content-headline-15" src="{{this.images}}eox-logo-rot.svg" data-holder-rendered="true" style="margin-top: -5px;"> {{language.theo.series}}</div>
		</div>
	</div>

	<!----------------------------------- /.container view 1200 --------------------------------------------------------->
	<!-- Content Row INTRO -->
	<div class="row">
	    <div class="col-md-12 mb-3 text-lg-center">
			  <h2 class="mb-3"><img class="svg-eox-content-headline-40" src="{{this.images}}eox-logo-rot.svg" data-holder-rendered="true" style="margin-top: -13px;"> {{language.theo.view}}</h2>
		</div>
	</div>
	  <!-- /.container -->
	<div class="row">
		<div class="col-md-6 mb-5 mt-5">
			<h3 class="mb-3 ml-lg-5"><span >{{language.theo.slides_functions.title}}</span></h3>
			<p class="ml-lg-5 w-75" [innerHTML]="language.theo.slides_functions.desc[0]"></p>
			<div class="d-block w-100 ml-lg-5">
				<ol class="carousel-indicators carousel-indicators-sigma">
					<li data-target="#carousel-thumb010" *ngFor="let slide of language.theo.slides_functions.slides; let i = index" [ngClass]="slide.active" [attr.data-slide-to]="i">
						<img class="d-block w-100" src="{{this.images + slide.thumbnail}}" class="img-fluid">
					</li>
				</ol>
			</div>
		</div>
		<div class="col-md-6 mb-5">

			<!--Carousel Wrapper-->
			<div id="carousel-thumb010" class="carousel-sigma-ebike carousel slide carousel-fade carousel-thumbnails" data-ride="carousel" data-interval="false">
					<!--Slides-->
					<div class="carousel-inner" role="listbox">
						<div class="carousel-item text-center" *ngFor="let slide of language.theo.slides_functions.slides" [ngClass]="slide.active">
							<img class="text-center" src="{{this.images + slide.image}}" class="img-fluid" alt="EOX VIEW 1200">
						</div>
					</div>
					<!--/.Slides-->

				</div>
				<!--/.Carousel Wrapper-->
		</div>
	</div>
<!-- /.container -->
</div>

<div class="wrapper-full-size bg-montage mb-5"><!--PM (06.08.2019) container was added-->
	<div class="row">
		<div class="col-md-12 col-lg-6">

	<div id="carouselMontageCaptions" class="carousel slide carousel-fade" data-ride="carousel" data-interval="false">
	    <ol class="carousel-indicators ">
	      <li data-target="#carouselMontageCaptions" class="fc-white mb-2" *ngFor="let slide of language.theo.slides_positioning.slides; let i = index" [ngClass]="slide.active" [attr.data-slide-to]="i">
		      <p class="mt-1 mb-2">{{slide.thumbnail}}</p>
	      </li>
	    </ol>
	    <div class="carousel-inner">
		    <div class="carousel-item" *ngFor="let slide of language.theo.slides_positioning.slides" [ngClass]="slide.active">
			    <img class="d-block w-100" src="{{this.images + slide.image}}" data-holder-rendered="true">
		    </div>
	    </div>
	  </div>

	</div>
		<div class="col-md-12 col-lg-6 bg-montage fc-white">
			<h3 class="mb-3 mt-5 ml-3 ml-lg-5"><span  [innerHTML]="language.theo.slides_positioning.title"></span></h3>
			<p class="ml-3 ml-lg-5 mr-5 mb-5 txt-width-pos" [innerHTML]="language.theo.slides_positioning.desc[0]"></p>
		</div>
	</div>

</div>

<!-- /.container -->
<div class="container">

<div class="row">
	<div class="col-lg-5 col-md-6 mb-5 mt-md-5">
		<h3 class="mb-3 ml-md-5 mt-md-5"><span >{{language.theo.setup_comfort.title}}</span></h3>
		<p class="ml-md-5" style="width:75%;" [innerHTML]="language.theo.setup_comfort.desc[0]"></p>
	</div>
	<div class="col-lg-7 col-md-6 mb-5 mt-md-5">
		<img src="{{this.images}}view-setup.png" class="img-fluid" alt="E-BIKE">
	</div>
</div>

	<!-- Content Row LINE + RUBRIK ICON -->
	<div id="rubrik-ebike-8" class="rubrik-ebike row">
	    <div class="col-md-12 mb-3 rubrik-wrapper">
			<div class="rubrik-headline"><img class="svg-eox-content-headline-15" src="{{this.images}}eox-logo-rot.svg" data-holder-rendered="true" style="margin-top: -5px;"> {{language.theo.series}}</div>
		</div>
	</div>
<!----------------------------------- /.container view 1300 --------------------------------------------------------->

	<!-- Content Row INTRO -->
	<div id="rubrik-ebike-4" class="row">
		<div class="col-md-12 mb-3 text-lg-center">
			<h2 class="mb-3"><img class="svg-eox-content-headline-40" src="{{this.images}}eox-logo-rot.svg" data-holder-rendered="true" style="margin-top: -13px;"> {{language.theo.view_1300}}</h2>
	  	</div>
  	</div>


	<!-- /.container -->
	<div class="row">
		<div class="col-md-12 col-lg-7 mb-5 mt-5">
			<h3 class="mb-3 ml-lg-5"><span >{{language.theo.slides_functions_1300.title}}</span></h3>
			<p class="ml-lg-5 w-75" [innerHTML]="language.theo.slides_functions_1300.desc[0]"></p>
			<div class="d-block w-100 ml-lg-5">
				<ol class="carousel-indicators carousel-indicators-sigma">
					<li data-target="#carousel-thumb015" *ngFor="let slide of language.theo.slides_functions_1300.slides; let i = index" [ngClass]="slide.active" [attr.data-slide-to]="i">
						<img class="d-block w-100" src="{{this.images + slide.thumbnail}}" class="img-fluid">
					</li>
				</ol>
			</div>
		</div>
		<div class="col-md-12 col-lg-5 mb-5">

			<!--Carousel Wrapper-->
			<div id="carousel-thumb015" class="carousel-sigma-ebike carousel slide carousel-fade carousel-thumbnails" data-ride="carousel" data-interval="false">
					<!--Slides-->
					<div class="carousel-inner" role="listbox">
						<div class="carousel-item text-center" *ngFor="let slide of language.theo.slides_functions_1300.slides" [ngClass]="slide.active">
							<img class="text-center" src="{{this.images + slide.image}}" class="img-fluid" alt="EOX VIEW 1300">
						</div>
					</div>
					<!--/.Slides-->

				</div>
				<!--/.Carousel Wrapper-->
		</div>
	</div>

  <!-- /.container -->
</div>

 <!-- /.container -->
<div class="wrapper-full-size bg-montage mb-5 mb-lg-0"><!--PM (06.08.2019) container was added-->
	<div class="row">
		<div class="col-md-12 col-lg-6 pr-0 ">
			<div id="carouselMontageCaptions1300" class="carousel slide carousel-fade" data-ride="carousel" data-interval="false">
				<ol class="carousel-indicators ">
				<li data-target="#carouselMontageCaptions1300" class="fc-white mb-2" *ngFor="let slide of language.theo.slides_positioning_1300.slides; let i = index" [ngClass]="slide.active" [attr.data-slide-to]="i">
					<p class="mt-1 mb-2">{{slide.thumbnail}}</p>
				</li>
				</ol>
				<div class="carousel-inner">
					<div class="carousel-item" *ngFor="let slide of language.theo.slides_positioning_1300.slides" [ngClass]="slide.active">
						<img class="d-block w-100" src="{{this.images + slide.image}}" data-holder-rendered="true">
					</div>
				</div>
			</div>
		</div>
		<div class="col-md-12 col-lg-6 bg-montage fc-white">
			<h3 class="mb-3 mt-3 mt-lg-2 mt-xl-5 ml-3 ml-lg-0 ml-xl-4"><span  [innerHTML]="language.theo.slides_positioning_1300.title"></span></h3>
			<p class="ml-3 ml-lg-0 ml-xl-4 mr-2 mb-5 mb-lg-1 txt-width-pos" [innerHTML]="language.theo.slides_positioning_1300.desc[0]"></p>
		</div>
	</div>
</div>
 <!-- /.container -->

 <!-- /.container -->
 <div class="container">

	<div class="row">

		<div class="col-lg-5 col-md-6 mb-5 mt-md-5">
			<h3 class="mb-3 ml-md-5 mt-md-5"><span >{{language.theo.setup_comfort_1300.title}}</span></h3>
			<p class="ml-md-5" style="width:75%;" [innerHTML]="language.theo.setup_comfort_1300.desc[0]"></p>
		</div>
		<div class="col-lg-7 col-md-6 mb-5 mt-md-5">
			<img src="{{this.images}}view-setup-1300.png" class="img-fluid" alt="E-BIKE">
		</div>
	</div>

<!-- Content Row LINE + RUBRIK ICON -->
<div id="rubrik-ebike-5" class="rubrik-ebike row">
    <div class="col-md-12 mb-5 rubrik-wrapper">
		<div class="rubrik-headline"><img class="svg-eox-content-headline-15" src="{{this.images}}eox-logo-rot.svg" data-holder-rendered="true" style="margin-top: -5px;"> {{language.theo.series}}</div>
	</div>
</div>
<!-- Content Row INTRO -->
<div class="row">
      <div class="col-md-12 mb-3 text-lg-center">
		  <h2 class="mb-3"><img class="svg-eox-content-headline-40" src="{{this.images}}eox-logo-rot.svg" data-holder-rendered="true" style="margin-top: -13px;"> {{language.theo.ribbon}}</h2>
	</div>
</div>
<!-- /.container ribbon butler --><!--ML (12.01.2021) container was added-->

<div class="row">
	<div class="col-md-12 col-lg-7 mb-5 mt-5">
		<h3 class="mb-3 ml-lg-5"><span  [innerHTML]="language.theo.slides_positioning_ribbon.title"></span></h3>
		<p class="ml-lg-5" [innerHTML]="language.theo.slides_positioning_ribbon.desc[0]"></p>
		<div class="d-block w-100 ml-lg-5">
			<ol class="carousel-indicators carousel-indicators-sigma">
				<li data-target="#carousel-thumb-ribbon" *ngFor="let slide of language.theo.slides_positioning_ribbon.slides; let i = index" [ngClass]="slide.active" [attr.data-slide-to]="i">
					<img class="d-block w-100" src="{{this.images + slide.thumbnail}}" class="img-fluid">
				</li>
			</ol>
		</div>
	</div>
	<div class="col-md-12 col-lg-5 mb-5">


		<div id="carousel-thumb-ribbon" class="carousel-sigma-ebike carousel slide carousel-fade carousel-thumbnails" data-ride="carousel" data-interval="false">

				<div class="carousel-inner" role="listbox">
					<div class="carousel-item text-center" *ngFor="let slide of language.theo.slides_positioning_ribbon.slides" [ngClass]="slide.active">
						<img class="text-center" src="{{this.images + slide.image}}" class="img-fluid" alt="EOX RIBBON BUTLER">
					</div>
				</div>


			</div>

	</div>
</div>







<!-- Content Row LINE + RUBRIK ICON -->
<div id="rubrik-ebike-3" class="rubrik-ebike row">
    <div class="col-md-12 mb-5 rubrik-wrapper">
		<div class="rubrik-headline"><img class="svg-eox-content-headline-15" src="{{this.images}}eox-logo-rot.svg" data-holder-rendered="true" style="margin-top: -5px;"> {{language.theo.series}}</div>
	</div>
</div>
<!-- Content Row INTRO -->
<div class="row">
      <div class="col-md-12 mb-3 text-lg-center">
		  <h2 class="mb-3"><img class="svg-eox-content-headline-40" src="{{this.images}}eox-logo-rot.svg" data-holder-rendered="true" style="margin-top: -13px;"> {{language.theo.app}}</h2>
	</div>
</div>
  <!-- /.container -->
<div class="row">
	<div class="col-md-6 mb-5 ">
		<!--Carousel Wrapper-->
		<div id="carousel-thumb02" class="carousel-sigma-ebike carousel slide carousel-fade carousel-thumbnails" data-ride="carousel" data-interval="false">
				<!--Slides-->
				<div class="carousel-inner" role="listbox">
					<div class="carousel-item text-center" *ngFor="let slide of language.theo.slides_eox_app.slides" [ngClass]="slide.active">
						<img class="text-center" src="{{this.images + slide.image}}" alt="ROX 12.0 SPORT ">
					</div>
				</div>
				<!--/.Slides-->

			</div>
			<!--/.Carousel Wrapper-->
	</div>
	<div class="col-md-6 mb-5">
		<h3 class="mb-3 mt-5"><span>{{language.theo.slides_eox_app.title}}</span></h3>
		<p class="mb-3 mr-4" [innerHTML]="language.theo.slides_eox_app.desc[0]"></p>
		<p class="mb-3 mr-4"><a href="https://play.google.com/store/apps/details?id=com.sigmasport.ebikeapp&hl=de" target="_blank"><img class="google-play-store-badge mt-3" height="46px;" style="padding-right: 15px;" src="{{this.images}}google-play-badge.png" ></a> <a href="https://apps.apple.com/de/app/sigma-eox/id1496938608" target="_blank"><img class="apple-store-badge mt-3" style="height: 46px;" src="{{this.images}}appstore-badge.svg" ></a></p>
		<!--<p class="mb-3 mr-4"><a href="https://apps.apple.com/de/app/sigma-eox/id1496938608"><img class="apple-store-badge" src="{{this.images}}appstore-badge.svg" ></a></p>-->
		<!-- PM (06.08.2019) @deprecated &shy; is now used instead
    <p class="d-none d-lg-block" [innerHTML]="language.theo.slides_eox_app.desc[0]"></p>
		<p class="d-lg-none">{{language.theo.slides_eox_app[1]}}</p>
		-->
		<div class="d-block w-100 mt-5">
				<ol class="carousel-indicators carousel-indicators-sigma">
					<li data-target="#carousel-thumb02" *ngFor="let slide of language.theo.slides_eox_app.slides; let i = index" [ngClass]="slide.active" [attr.data-slide-to]="i">
						<img class="d-block w-100" src="{{this.images + slide.thumbnail}}" class="img-fluid">
					</li>
				</ol>
			</div>
	</div>

</div>
 <!-- /.container -->
</div>
 <!-- /.container -->
<!-- /.container -->
<div class="wrapper-full-size bg-connect mb-5">
	<div class="bg-black-content-half-r h-inherit ">
		<div class="container">
			<div class="row">
				<div class="col-md-6 mt-lg-5 fc-white">
						<div class="ml-2 ml-md-5 mt-5 mb-3"><img src="{{this.images}}icons/icon-cloud.png" alt="E-BIKE"><img src="{{this.images}}icons/icon-strava.png" alt="E-BIKE"> <img src="{{this.images}}icons/icon-komoot.png" alt="E-BIKE"></div>
						<h3 class="mb-3 ml-2 ml-md-5"><span >{{language.theo.setup_connected.title}}</span></h3>
						<p class="ml-2 ml-md-5 mr-md-5"[innerHTML]="language.theo.setup_connected.desc[0]"></p>
				</div>
			</div>
		</div>
	</div>
</div>
	<!-- /.container -->
<div class="wrapper-full-size mt-6">
	<div class="container">
		<div class="row">
			<div class="col-lg-8 col-md-6 mb-5">
				<img src="{{this.images}}app-custom-screens.jpg" class="img-fluid" alt="E-BIKE">
			</div>
			<div class="col-lg-4 col-md-6">
				<h3 class="mb-3 ml-md-5 mt-5 "><span >{{language.theo.setup_configurable.title}}</span></h3>
				<p class="ml-md-5" [innerHTML]="language.theo.setup_configurable.desc[0]"></p>

			</div>
		</div>
	</div>
</div>
  <!-- /.container -->

  <!-- /.container -->
<div class="container">
<!-- /.container -->
<!-- Content Row LINE + RUBRIK ICON -->
<div class="rubrik-ebike row">
    <div class="col-md-12 mb-5 rubrik-wrapper">
		<div class="rubrik-headline"><span class="rubrik-headline-color-red">{{language.theo.our}}</span> {{language.theo.services}}</div>
	</div>
</div>
<div class="row">
      <div class="col-md-12 col-lg-6 mb-5" *ngFor="let card of language.theo.cards_services">
        <div class="card">
			<div class="h-100 img-box-color">
				<img  src="{{this.images + card.image}}" class="img-fluid" alt="E-BIKE">
			</div>
		  <div class="card-body pl-0">
			<h5 class="card-title">{{card.title}}</h5>
			<p class="card-text mr-md-5" [innerHTML]="card.desc[0]"></p>
		  </div>
        </div>
      </div>
 </div>


	<!--<section-process [language]="language" [page]="slug"></section-process>-->

</div>
<!-- /.container -->


<!--[/BODY]-->

<section-contact [language]="language"></section-contact>
<section-footer [language]="language"></section-footer>
