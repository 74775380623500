import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {Slide} from '../../utils/Slide';
import {Section} from '../../utils/Section';

@Component({
    selector: 'section-slider',
    templateUrl: './slider.component.html',
    styleUrls: ['./slider.component.less']
})

/**
 * @see https://codecraft.tv/courses/angular/quickstart/nesting-components-and-inputs/
 */
export class SliderComponent extends Section implements AfterViewInit {
  slug = 'slider';
  @Input() page: string;

  // tslint:disable-next-line:use-lifecycle-interface
  /*
  ngOnInit() {
    super.ngOnInit();
  }
  */

  ngAfterViewInit(): void {
    const
      slider = document.getElementById('carouselSigmaHead'),
      video = document.getElementById('eox_market_launch') as HTMLVideoElement
    if(slider && video){
      slider.addEventListener(
        'click',
        (event) => { //pause the video on click
          if(this.elt(event).matches('li')) video.pause()
        }
      )
    }
  }
}
