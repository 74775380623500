<footer class="py-5" id="footer_socials">
	<div>
		<a *ngFor="let social of language.theo.socials" href="{{social.href}}" target="_blank"><img src="{{images2}}icon-{{social.slug}}.png" alt=""></a>
	</div>
	<a class="text-center" href=""><img src="{{images2}}logo.png"></a>
</footer>
<footer class="row text-white" id="footer_meta">
	<div class="col-6 text-left">
		<a class="text-white" *ngFor="let meta of language.theo.meta" href="{{meta.href}}" target="_blank">{{meta.label}}</a>
	</div>
	<div class="col-6 text-right" [innerHTML]="language.theo.copyright"></div>
</footer>
