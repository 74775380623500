import {Component, ViewEncapsulation/*, AfterViewInit*/} from '@angular/core';
import {Page} from '../../utils/Page';
import * as $ from 'jquery';

@Component({
    selector: 'app-root',
    templateUrl: './integrated.component.html',
    styleUrls: ['./integrated.component.less'],
    encapsulation: ViewEncapsulation.None //prevent encapsulation of css definitions @ee https://angular.io/api/core/ViewEncapsulation    
})
export class IntegratedComponent extends Page {
    slug = 'integrated';
    integrated = 'active';
}
