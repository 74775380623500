import {Component, Input} from '@angular/core';
import {Section} from '../../utils/Section';

@Component({
    selector: 'section-process',
    templateUrl: './process.component.html'
})

/**
 * @see https://codecraft.tv/courses/angular/quickstart/nesting-components-and-inputs/
 */
export class ProcessComponent extends Section {
    slug = 'process';
    @Input() page: string;
}
