import {Thesaurus} from './thesaurus';
import {SlidesThumbnails} from '../utils/SlidesThumbnails';
import {SlideThumbnail} from '../utils/SlideThumbnail';
import {Info} from '../utils/Info';
import {Card} from '../utils/Card';

export class ThesaurusEN extends Thesaurus {

  label = 'English';
  //...
  agreed = 'Agreed';
  brochure = 'brochure';
  brochure_intro = 'In this brochure you will find all information<br>on our range of e-bikes.';
  cookies = 'To improve the functionality of our site we use cookies. By using the page you agree to the use of cookies.';
  company = 'Company';
  components = 'of components';
  contact = 'Contact';
  contact_person = 'Contact person';
  contact_person_b2b = 'Contact Business (OEM)';
  contact_person_b2c = 'Contact Consumer';
  ebike_ready = new Info(
    `E-Bike ready`,
    'The e-bike ready series offers you and your customers maximum flexibility and a wealth of additional functions and values thanks to the wirelessly connected bike computer.'
  );
  electric = '100% Electrical - 100% SIGMA';
  eox_headline = this.eox;
  eox_series = new Info(
    ' series',
    'Ergonomic, innovative, smart - the EOX<sup>&reg;</sup> series sets new standards for<br>e-bikes with a multitude of unique features.'
  );
  home_head = [
    new Card(
      'icon-1.png',
      'Our<br>expertise',
      'With over 40 years of experience and more than 70,000,000 bike computers sold, we have come to know the bike industry and customer requirements very precisely. This expertise makes our products unique and durable.'
    ),
    new Card(
      'icon-2.png',
      'German<br>quality',
      'All SIGMA products are developed by our  product management team in Neustadt an der Weinstraße. With the location in Germany we guarantee a fast, flexible and solution-oriented cooperation.'
    ),
    new Card(
      'icon-3.png',
      'Partnership<br>cooperation',
      'We would be a partner at your side to support you from conception to development. Together we work out the best possible solution for your individual requirements. In addition, we also provide an excellent customer service, which takes care of questions and problems.'
    ),
    new Card(
      'icon-4.png',
      'Individual<br>concepts',
      'You will receive from us a complete solution, from the first product idea up to the finished result, from a single source. We offer different setups, so you can choose the most practical option for you.'
    ),
    new Card(
      'icon-5.png',
      'Technological<br>infrastructure',
      'Our technological foundation goes beyond pure product hardware. We offer bicycle-specific in-house solutions for the areas of app development, cloud services, navigation, digital terrain modeling and OSM maps. In the future, you can rely on our know-how for your projects.'
    )
  ];
  home_integrated_app = {
    title: ` APP`,
    desc: 'The EOX<sup>&reg;</sup> app logs the tracks you ride, which you can easily analyze and share from anywhere. In addition, the app can be used to individually configure the values shown on the EOX<sup>&reg;</sup> VIEW 1200.',
    target: '#rubrik-ebike-3'
  };
  integrated_intro = {
    large: 'The e-bike integrated series are fixed and wired solutions,<br>who communicate with the e-bike.',
    small: 'The e-bike integrated series are fixed and wired solutions, who communicate with the e-bike.'
  };
  integration = 'Integration';
  language = 'Language';
  meta = [
    {
      label: 'Imprint',
      href: `https://sigmasport.com/${this.key}/impressum`
    },
    {
      label: 'Data privacy',
      href: `https://sigmasport.com/${this.key}/datenschutz`
    }
  ];
  more_info = 'More info';
  our = 'our';
  partners = 'partners';
  pioneer = `SIGMA SPORT is a pioneer in the development of bike computers and has always stood for innovation, know-how and quality. With our e-bike products, we are redesigning the future of e-mobility.`;
  process = [
    'Development process',
    'Development<br>process'
  ];
  process_steps = {
    integrated: [
      new Card('prozess-icon-int-01.png','1','Select<br>components'),
      new Card('prozess-icon-int-02.png','2','Align<br>protocols'),
      new Card('prozess-icon-int-03.png','3','Customize<br>components')
    ],
    ready: [
      new Card('prozess-icon-01.png','1','Select<br>components'),
      new Card('prozess-icon-02.png','2','Align<br>protocols'),
      new Card('prozess-icon-03.png','3','Customize<br>components')
    ]
  };
  routes = {
    integrated: 'INTEGRATED',
    ready: 'READY'
  };
  rox12 = new Info(
    'ROX 12.0 SPORT',
    'The ROX 12.0 SPORT takes e-bike rides to a new level while offering a wealth of features such as professional training or innovative map navigation.'
  );
  series = 'series';
  cards_services = [
    new Card(
      'service-teaser-01.png',
      'Data processing',
      'The EOX<sup>&reg;</sup> app can be connected to the data back end to analyze specific e-bike data, such as information on battery life, levels of support, or maintenance intervals. For the evaluation and analysis of this data, we would be happy to establish contact with reputable providers.'
    ),
    new Card(
      'service-teaser-02.png',
      'Individual adaptation',
      'We can customize our e-bike products to your needs, be it the integration of your Brand logo, housing colors or view and selection of the displayed values of the EOX<sup>&reg;</sup> app. Contact us.'
    )
  ];
  setup_comfort = new Info(
    'Setup for comfort & frequent travelers',
    'The EOX<sup>&reg;</sup> REMOTE 500 is connected to the e-bike system via Can Bus cable and to the EOX<sup>&reg;</sup> VIEW 1200 via UART cable. The display shows all the values that have been covered, while the EOX<sup>&reg;</sup> app records and saves them via Bluetooth Smart.'
  );
  setup_comfort_1300 = new Info(
    'Connectivity: Two setup options',
    'The EOX<sup>&reg;</sup> VIEW 1300 is connected to the EOX® REMOTE 500 via BLE. The remote communicates with the e-bike via CAN bus. Alternatively, depending on the system, the EOX<sup>&reg;</sup> VIEW 1300 can also be connected directly to another component on the e-bike via BLE or ANT+. The display shows all trip data, while the SIGMA EOX<sup>&reg;</sup> app (if connected via BLE) also records and saves them.'
  );
  setup_configurable = new Info(
    'Individually configurable',
    'With the EOX<sup>&reg;</sup> app, the values displayed on the EOX<sup>&reg;</sup> VIEW products can be individually selected and changed at any time. This allows the user to display exactly the values he needs to see on the display.'
  );
  setup_connected = new Info(
    'Always connected',
    'The recorded trips can be uploaded to the free SIGMA CLOUD. The activities can be shared at any time via third-party portals such as Strava, komoot, Facebook, Instagram, Twitter and Whatsapp.'
  );
  setup_lifestyle = new Info(
    'Setup for lifestyle, sport & purists',
    'The EOX<sup>&reg;</sup> REMOTE 500 is connected to the e-bike system via Can Bus cable and has a Bluetooth Smart interface. In this way it can communicate with the EOX<sup>&reg;</sup> app, which display and save the covered values.'
  );
  setup_trips = new Info(
    'Setup for sports &<br>cycling',
    'The ROX 12.0 SPORT is wirelessly linked to the EOX<sup>&reg;</sup> REMOTE 500, which transmits the bike data from the e-bike system to the ROX 12.0 SPORT via the Can Bus cable.'
  );


  slides_eox_app = new SlidesThumbnails(
    'Individualize, analyze, share',
    [
      'The EOX<sup>&reg;</sup> app offers analysis options as well as the possibility for customization. The app can be used to specify which riding data is displayed on the EOX<sup>&reg;</sup> VIEW products. To share trips anytime, anywhere, numerous interfaces to social media channels are available.'
    ],
    new SlideThumbnail('app-ueberblick-01.jpg', 'thumb-eox-app-01.jpg', true),
    new SlideThumbnail('app-ueberblick-02.jpg', 'thumb-eox-app-02.jpg'),
    new SlideThumbnail('app-ueberblick-03.jpg', 'thumb-eox-app-03.jpg')
  );
  slides_eox_remote = new SlidesThumbnails(
    'Smart control center',
    ['With ergonomic design, ambient light sensor and Bluetooth Smart integration, the EOX<sup>&reg;</sup> REMOTE 500 is a unique product in its class. The LED indicator makes all e-bike information visible even without a display.'],
    new SlideThumbnail(`remote-ueberblick-01-${this.key}.jpg`, 'thumb-remote-01.jpg', true),
    new SlideThumbnail(`remote-ueberblick-02-${this.key}.jpg`, 'thumb-remote-02.jpg'),
    new SlideThumbnail(`remote-ueberblick-03-${this.key}.jpg`, 'thumb-remote-03.jpg'),
    new SlideThumbnail(`remote-ueberblick-04-${this.key}.jpg`, 'thumb-remote-04.jpg'),
    new SlideThumbnail(`remote-ueberblick-05-${this.key}.jpg`, 'thumb-remote-05.jpg')
  );
  slides_functions = new SlidesThumbnails(
    'Aesthetics and function',
    ['The EOX<sup>&reg;</sup> VIEW 1200 attracts with clean lines and a flat design. The sensor-controlled backlight and the hi-resolution display show all data clearly in all weather conditions and in any riding situation.'],
    new SlideThumbnail('view-ueberblick-01.jpg', 'thumb-view-01.jpg', true),
    new SlideThumbnail('view-ueberblick-02.jpg', 'thumb-view-02.jpg'),
    new SlideThumbnail('view-ueberblick-03.jpg', 'thumb-view-03.jpg'),
    new SlideThumbnail('view-ueberblick-04.jpg', 'thumb-view-04.jpg'),
    new SlideThumbnail('view-ueberblick-05.jpg', 'thumb-view-05.jpg'),
    new SlideThumbnail('view-ueberblick-06.jpg', 'thumb-view-06.jpg'),
  );
  slides_functions_1300 = new SlidesThumbnails(
    'Design and functionality',
    ['With its flat design, the cable-free EOX<sup>&reg;</sup> VIEW 1300 fits discreetly into the overall picture of the e-bike and ensures a tidy cockpit. It is equipped with a long-life battery. This allows it to be rotated out of the mount like a regular bike computer. The secure data connection is guaranteed by the high-performance ANT+ and Bluetooth interface. With 3 buttons at the underside, the e-bike computer is easy to use. The transflective FSTN display ensures best readability even in direct sunlight. An integrated sensor automatically adjusts the display illumination to the ambient light. '],
    new SlideThumbnail('view-1300-ueberblick-03.jpg', 'thumb-view-1300-03.jpg', true),
    new SlideThumbnail('view-1300-ueberblick-02.jpg', 'thumb-view-1300-02.jpg'),
    new SlideThumbnail('view-1300-ueberblick-01.jpg', 'thumb-view-1300-01.jpg'),
    new SlideThumbnail('view-1300-ueberblick-04.jpg', 'thumb-view-1300-04.jpg'),
    new SlideThumbnail('view-1300-ueberblick-07.jpg', 'thumb-view-1300-07.jpg'),
    new SlideThumbnail('view-1300-ueberblick-05.jpg', 'thumb-view-1300-05.jpg'),
    new SlideThumbnail('view-1300-ueberblick-06.jpg', 'thumb-view-1300-06.jpg')
  );
  slides_led = new SlidesThumbnails(
    'Innovative<br>LED indicator',
    [
      'Thanks to the six-colour LED indicator, the most important information such as support level, battery status and error message are displayed directly on the EOX<sup>&reg;</sup> REMOTE 500. This makes the use of the remote easy and self-explanatory.'
    ],
    new SlideThumbnail('led-indikator-01.jpg', 'BATTERY&shy;STATUS',true),
    new SlideThumbnail('led-indikator-02.jpg', 'ASSIST MODE'),
    //new SlideThumbnail('led-indikator-03.jpg', 'BLE PAIRING'),
    new SlideThumbnail('led-indikator-04.jpg', 'ERROR MESSAGE')
  );
  slides_positioning = new SlidesThumbnails(
    'Individual positioning',
    ['The flat design of the EOX<sup>&reg;</sup> VIEW 1200 allows numerous mounting and integration options on the e-bike. The display can be mounted on the stem as well as on the handlebar or integrated in bicycle components.'],
    new SlideThumbnail('view-montage-01.jpg', '01',true),
    new SlideThumbnail('view-montage-02.jpg', '02'),
    //new SlideThumbnail('view-montage-03.jpg', '03'),
    new SlideThumbnail('view-montage-04.jpg', '03'),
    //new SlideThumbnail('view-montage-05.jpg', '05')
  );
  slides_positioning_1300 = new SlidesThumbnails(
    'Precise positioning',
    ['Three different brackets allow the ideal positioning for every type of e-bike. The OVER STEM BUTLER (picture 1, picture 2) for mounting directly above the stem of the MTB handlebars. There is also the possibility to use the classic SIGMA handlebar mount for cable-free bike computers (picture 3).'],
    new SlideThumbnail('view-1300-montage-01.jpg', '01',true),
    new SlideThumbnail('view-1300-montage-02-explosive.jpg', '02'),
    new SlideThumbnail('view-1300-montage-04.jpg', '03'),
    new SlideThumbnail('view-1300-montage-03.jpg', '04')
  );
  slides_positioning_ribbon = new SlidesThumbnails(
    'All-in-One',
    ['The RIBBON BUTLER can be mounted on the handlebars in a few easy steps. The SHIMANO STEPS EW-EN100 connector unit is then inserted. The Shimano components\' two LEDs and the RIBBON BUTLER\'s button can now be used to switch the e-bike\'s light on and off and enable and display support levels during a ride. The all-in-one solution is ready to create a tidy cockpit.'],
    new SlideThumbnail('ribbon-01.jpg', 'thumb-ribbon-01.jpg', true),
    new SlideThumbnail('ribbon-02.jpg', 'thumb-ribbon-02.jpg')
  );
  slides_rox12 = new SlidesThumbnails(
    'Maximum performance',
    ['The ROX 12.0 SPORT is the high end GPS bike computer from SIGMA. Via wireless interfaces such as ANT+ LEV or Bluetooth Smart, it communicates with compatible e-bike systems such as SHIMANO STEPS. In addition to the display and logging of e-bike values, the ROX 12.0 SPORT also offers classic functions such as professional training system, innovative map navigation and touch display.'],
    new SlideThumbnail(`rox-12-ueberblick-01-${this.key}.jpg`, 'thumb-rox12-01.jpg',true),
    new SlideThumbnail(`rox-12-ueberblick-02-${this.key}.jpg`, 'thumb-rox12-02.jpg'),
    new SlideThumbnail(`rox-12-ueberblick-03-${this.key}.jpg`, 'thumb-rox12-03.jpg'),
    new SlideThumbnail(`rox-12-ueberblick-04-${this.key}.jpg`, 'thumb-rox12-04.jpg'),
    new SlideThumbnail(`rox-12-ueberblick-05-${this.key}.jpg`, 'thumb-rox12-05.jpg')
  );

  to_product = 'to product';
  to_rox12 = `To<br>ROX 12.0 SPORT<br>website`;
  video = 'Product video';
  your = 'Your';
}
