import {Component, Input} from '@angular/core';
import {Section} from '../../utils/Section';

@Component({
    selector: 'section-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.less']
})

/**
 * @see https://codecraft.tv/courses/angular/quickstart/nesting-components-and-inputs/
 */
export class ContactComponent extends Section {
    slug = 'contact';
}
