import { Component } from '@angular/core';
import {Page} from '../../utils/Page';

@Component({
  selector: 'app-root',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less']
})
export class HomeComponent extends Page {
  slug = 'home';
}
