/**
 * PM (04.07.2019)
 */
import {Element} from './Element';
import {LanguageService} from './language.service';
import * as $ from 'jquery';
import { AfterViewInit, Directive } from '@angular/core';

@Directive()
export class Page extends Element implements AfterViewInit {
    readonly scroller = $('html, body') //html for safari, body for the rest of the major browsers


    constructor(public language: LanguageService){ super() }

    ngOnInit(){
        super.ngOnInit();
        this.language.set(); //DO NOT REMOVE THIS LINE: the language must be set before the html snippet are rendered
        this.setClickHandlers()
    }

    ngAfterViewInit(): void {}


    /**
     * PM (17.07.2019) standalone
     * this function is used to set the custom click events handler; among others
     * - the slider custom thumbnails
     * - the scrollable anchors
     */
    private setClickHandlers(){
        const active = this.active, scroller = this.scroller
        //DO NOT MERGE
        this.dom
            .on('click', 'ol.carousel-indicators > li', function(){
                //PM (17.07.2019) improvement due to the fact that more than one slider will now be presented some pages
                this.parentElement.querySelector(`li.${active}`).classList.remove(active) //remove the previously active thumbnail
                this.classList.add(active) //set the new active thumbnail

                //$('#thumbnail-list-01 > li.'+ active).removeClass(active);
                //$(e.currentTarget).addClass(active) //set the new active thumbnail
            })
            .on('click', `a.${this.prefix}scrollable[href]`, function(e){ //scroll event handler
                const query = this.getAttribute('href')
                if(query){
                    e.preventDefault() //prevent the default behaviour

                    const elt = document.querySelector(query)
                    if(elt) scroller.animate({scrollTop: $(elt).offset().top}, 1000) //1sec
                }
            })
    }
}
