<!-- Content Row LINE + RUBRIK ICON -->
<div id="rubrik-ebike-5" class="rubrik-ebike row">
	<div class="col-md-12  rubrik-wrapper">
		<div class="rubrik-headline"><span class="rubrik-headline-color-red">{{language.theo.integration}}</span>  {{language.theo.components}}</div>
	</div>
</div>
<div class="row">
	<div class="col-md-12 mb-3 text-lg-center">
		<h1 class="mb-5 d-none d-sm-block">{{language.theo.process[0]}}</h1>
		<h1 class="mb-5 d-sm-none" [innerHTML]="language.theo.process[0]"></h1>
	</div>
</div>
<div class="row mb-5 prozess-wrapper">
	<div class="col-lg-4 col-md-12 text-lg-center prozess-icon-bg-l" *ngFor="let step of language.theo.process_steps[page]">
		<div class="prozess-icon text-center"><img src="{{this.images + step.image}}"></div>
		<div class="row mt-3">
			<div class="col-6 text-right"><span>{{step.title}}</span> </div>
			<div class="col-6 text-left"><strong [innerHTML]="step.desc[0]"></strong></div>
		</div>
	</div>
</div>
<!-- /.container -->
