import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';


//PM (04-05.07.2019)
import {HomeComponent} from './pages/home/home.component';
import {IntegratedComponent} from './pages/integrated/integrated.component';
import {ReadyComponent} from './pages/ready/ready.component';
import {CookiesComponent} from './sections/cookies/cookies.component';
import {NavComponent} from './sections/nav/nav.component';
import {SliderComponent} from './sections/slider/slider.component';
import {ContactComponent} from './sections/contact/contact.component';
import {FooterComponent} from './sections/footer/footer.component';
import {ProcessComponent} from './sections/process/process.component';
import {CookieService} from 'ngx-cookie-service';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    IntegratedComponent,
    ReadyComponent,
    CookiesComponent,
    NavComponent,
    SliderComponent,
    ContactComponent,
    FooterComponent,
    ProcessComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [CookieService],
  bootstrap: [AppComponent]
})
export class AppModule { }
