/**
 * PM (23.07.219)
 */

export class Info {
    public desc: string[]

    constructor(public title: string, ...desc: string[]){
        this.desc = desc
    }
}
