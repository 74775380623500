/**
 * PM (23.07.219)
 */
import {SlideThumbnail} from './SlideThumbnail';
import {Info} from './Info';

export class SlidesThumbnails extends Info {
    slides: SlideThumbnail[];

    constructor(public title: string, public desc: string[], ...slides: SlideThumbnail[]){
        super(title)
        this.desc = desc
        this.slides = slides;
    }
}
