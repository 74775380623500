import {SlidesThumbnails} from '../utils/SlidesThumbnails';
import {Info} from '../utils/Info';
import {Card} from '../utils/Card';
import {Slide} from '../utils/Slide';

export class Thesaurus {
  constructor(protected key: String){}

  static company_url = 'https://sigmasport.com/';

  readonly _ = '???';
  readonly sigma = 'SIGMA SPORT';

  label: string;

  //...
  agreed: any;
  readonly app = 'APP';
  brochure: string;
  brochure_intro: string;
  cookies;
  company;
  components: string;
  contact: string;
  contact_person: string;
  contact_person_b2b: string;
  contact_person_b2c: string;
  readonly contact_person_mail = 'eox-sales@sigmasport.com';
  readonly contact_person_name = 'Lars Adloff';
  readonly contact_person_phone = '+49 (0) 63 21/ 91 20 - 251';
  readonly contact_person_mobile = '+49 (0) 151 25 96 15 21';
  readonly contact_person_title = 'Director Business Development';
  readonly contact_person_service_mail = 'service@sigmasport.com';
  readonly contact_person_service_name = 'SIGMA Service';
  readonly contact_person_service_phone = '+49 (0) 63 21/ 91 20 - 118';
  readonly copyright = `&copy; ${this.sigma} ${new Date().getFullYear()}`;
  readonly download = 'Download';
  readonly ebike = 'E-Bike';
  ebike_ready: Info;
  electric;
  readonly eox = 'EOX';
  readonly eox_app = `${this.eox} ${this.app}`;
  eox_headline: string;
  eox_series: Info;
  home_head: Card[];
  home_integrated_app: any;
  readonly integrated = 'Integrated'
  integrated_intro: any;
  integration: string;
  language: string;
  meta: any;
  more_info: string;
  our: string;
  partners: string;
  //readonly partners_list = [
  //  new Card('bafang.png', 'BAFANG'),
  //  new Card('sachs.png', 'SACHS Micro Mobility'),
  //  new Card('bmz.png', 'BMZ the innovation group'),
  //  new Card('tq.png', 'tq'),
  //  new Card('fazua.png', 'fazua'),
  //  new Card('maxon.png', 'maxon'),
  //  new Card('shimano.png', 'SHIMANO STEPS'),
  //  new Card('lev.png', 'LEV'),
  //  new Card('ergotec.png', 'ergo-tec'),
  //  new Card('komoot.png', 'komoot')
  //];
  readonly partners_list = [
    new Card('bafang.png', 'BAFANG'),
    new Card('bmz.png', 'BMZ the innovation group'),
    new Card('ergotec.png', 'ergo-tec'),
    new Card('fazua.png', 'fazua'),
    new Card('giant.png', 'giant-bicycles'),
    new Card('komoot.png', 'komoot'),
    //new Card('lev.png', 'LEV'),
    new Card('maxon.png', 'maxon'),
    new Card('nox.png', 'nox'),
    new Card('amprio.png', 'amprio'),
    new Card('sachs.png', 'SACHS Micro Mobility'),
    new Card('shimano.png', 'SHIMANO STEPS'),
    new Card('tq.png', 'tq'),
    new Card('zehus.png', 'zehus')
  ];
  pioneer: string;
  process: any;
  process_steps: any;
  readonly ready = 'ready';
  readonly remote = 'REMOTE 500';
  routes: {};
  rox12: Info;
  series: string;
  services = 'services';

  cards_services: Card[];

  setup_comfort: Info;
  setup_comfort_1300: Info;
  setup_configurable: Info;
  setup_connected: Info;
  setup_lifestyle: Info;
  setup_trips: Info;

  slides_eox_app: SlidesThumbnails;
  slides_eox_remote: SlidesThumbnails;
  slides_functions: SlidesThumbnails;
  slides_functions_1300: SlidesThumbnails;
  slides_led: SlidesThumbnails;
  slides_positioning: SlidesThumbnails;
  slides_positioning_1300: SlidesThumbnails;
  slides_positioning_ribbon: SlidesThumbnails;
  slides_rox12: SlidesThumbnails;
  //...
  readonly slides_head = () => [ //lazy invocation
    new Slide('sigma-e-bike-head-01.jpg', `${this.remote}`, [this.slides_eox_remote.title.replace('<br>', ' ')], true),
    new Slide('sigma-e-bike-head-02.jpg', `VIEW`, [this.slides_functions.title]),
    new Slide( 'sigma-e-bike-head-03.jpg', `${this.app}`, [this.slides_eox_app.title.replace(/, +/g, '<br>')] ),
  ];

  /*readonly*/ socials = [
    {
      slug: 'blog',
      href: 'https://sigma-inmotion.com/'
    },
    {
      slug: 'facebook',
      href: 'https://www.facebook.com/SIGMASPORTGermany'
    },
    {
      slug: 'instagram',
      href: 'https://www.instagram.com/sigma_sport_germany/'
    },
    {
      slug: 'youtube',
      href: 'https://www.youtube.com/user/SIGMAelektro'
    },
  ];
  to_product: string;
  to_rox12: string;
  readonly video: string;
  readonly view = 'VIEW 1200'
  readonly view_1300 = 'VIEW 1300'
  readonly ribbon = 'RIBBON BUTLER'
  your: string;
  //...
  readonly home_integrated = [
    {
      classs: 'bg-teaser-remote',
      title: this.remote,
      image: `eox-logo-w.svg`,
      target: '#rubrik-ebike-1'
    },
    {
      classs: 'bg-teaser-view',
      title: 'VIEW',
      image: `eox-logo-w.svg`,
      target: '#rubrik-ebike-2'
    }
  ];


  /**
   * this function is used to get the document title
   * https://angular.io/guide/set-document-title
   */
  title(){ return `${this.sigma} // ${this.ebike} - ${this.electric}` }
}
