<nav id="navigation" class="navbar navbar-expand-lg navbar-white background-white-trans border-nav-bottom fixed-top">

	<a class="navbar-brand" href="/"><img src="{{this.images2}}sigma-logo.png" class="sigma-logo" alt="SIGMA SPORT"></a>

	<!--PM (17.07.2019) @see https://graphemica.com/%E2%98%B0-->
	<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
		<span class="navbar-toggler-icon">&#9776;</span>
	</button>

	<div class="collapse navbar-collapse" id="navbarSupportedContent">
		<ul class="navbar-nav mr-auto container img-box-center">
			<li class="nav-item mr-md-5" *ngFor="let routeIn of routes" [ngClass]="routeIn == this.route ? 'active' : ''">
				<a class="nav-link fc-black " href="/{{routeIn}}">
					<span>{{language.theo.ebike}}</span>&nbsp;<span>{{language.theo.routes[routeIn]}}</span>
				</a>
			</li>
			<!--
			<li class="nav-item" [ngClass]="integrated">
				<a class="nav-link fc-black" href="e-bike-dedicated.html">E-BIKE INTEGRATED</a>
			</li>
			<li class="nav-item" [ngClass]="ready">
				<a class="nav-link fc-black" href="e-bike-ready.html">E-BIKE READY</a>
			</li>
			-->

			<li class="nav-item company">
				<a class="nav-link" href="{{thesaurus().company_url}}{{language.lang}}" target="_blank">{{language.theo.company}}</a>
			</li>
		</ul>
		<a class="nav-link company" href="{{thesaurus().company_url}}{{language.lang}}" target="_blank">{{language.theo.company}}</a>
		<a>|</a>
		<div class="nav-item dropdown">
			<a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" (click)="toggle()">
				{{language.theo.language}}
			</a>
			<div class="dropdown-menu" id="languages" aria-labelledby="navbarDropdown">
				<!--<a class="dropdown-item" *ngFor="let lang of language.langs" (click)="language.set(lang)">{{language.thesauri[lang].label}}</a>-->
				<a class="dropdown-item" *ngFor="let lang of language.langs" (click)="this.set(lang, $event)">{{language.thesauri[lang].label}}</a>
			</div>
		</div>
	</div>
</nav>
