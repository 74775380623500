<div class="wrapper-full-size mb-5">
	<div id="carouselSigmaHead" class="carousel slide carousel-fade" data-ride="carousel" data-interval="false"><!--data-interval="false"-->
        <ol class="carousel-indicators">
          <!--
          PM (29.07.2019) there is currently a bug
          <li data-target="#carouselSigmaHead" *ngFor="let slide of language.theo.slides_head(); let i = index" [attr.data-slide-to]="i" [ngClass]="slide.active"></li>
          -->
          <li data-target="#carouselSigmaHead" data-slide-to="0" class="active"></li>
          <li data-target="#carouselSigmaHead" data-slide-to="1" ></li>
          <li data-target="#carouselSigmaHead" data-slide-to="2" class=""></li>
        </ol>
        <div class="carousel-inner">
          <!--
          PM (29.07.2019) there is currently a bug
          <div class="carousel-item" *ngFor="let slide of language.theo.slides_head()" [ngClass]="slide.active">
            <img class="d-block w-100" src="{{this.images2}}{{slide.image}}" data-holder-rendered="true">
            <div class="carousel-caption d-none d-md-block position-in-slider">
              <h2 style="letter-spacing: 0.06em; font-weight: 100;">
                  <span class="font-weight-bolder">{{slide.title}}</span><br>
                  <span [innerHTML]="slide.desc[0]"></span>
              </h2>
            </div>
          </div>
          -->
          <div class="carousel-item active">
            <img class="d-block w-100" src="{{this.images2}}{{language.theo.slides_head()[0].image}}" data-holder-rendered="true">
            <div class="carousel-caption d-none d-md-block position-in-slider">
              <h2 style="letter-spacing: 0.06em; font-weight: 100;">
                <img class="svg-eox-slider" src="{{this.images}}eox-logo-w.svg" data-holder-rendered="true"> <span class="head-first">{{language.theo.slides_head()[0].title}}</span><br>
                <span class="head-sub mt-2" [innerHTML]="language.theo.slides_head()[0].desc[0]"></span>
              </h2>
            </div>
          </div>
          <div class="carousel-item">
            <img class="d-block w-100" src="{{this.images2}}{{language.theo.slides_head()[1].image}}" data-holder-rendered="true">
            <div class="carousel-caption d-none d-md-block position-in-slider">
              <h2 style="letter-spacing: 0.06em; font-weight: 100;">
                <img class="svg-eox-slider" src="{{this.images}}eox-logo-w.svg" data-holder-rendered="true" > <span class="head-first">{{language.theo.slides_head()[1].title}}</span><br>
                <span class="head-sub mt-2" [innerHTML]="language.theo.slides_head()[1].desc[0]"></span>
              </h2>
            </div>
          </div>
          <div class="carousel-item">
            <img class="d-block w-100" src="{{this.images2}}{{language.theo.slides_head()[2].image}}" data-holder-rendered="true">
            <div class="carousel-caption d-none d-md-block position-in-slider">
              <h2 style="letter-spacing: 0.06em; font-weight: 100;">
                <img class="svg-eox-slider" src="{{this.images}}eox-logo-w.svg" data-holder-rendered="true" > <span class="head-first">{{language.theo.slides_head()[2].title}}</span><br>
                <span class="head-sub mt-2" [innerHTML]="language.theo.slides_head()[2].desc[0]"></span>
              </h2>
            </div>
          </div>
        </div>
  </div>
</div>
