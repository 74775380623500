import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './pages/home/home.component';
import {IntegratedComponent} from './pages/integrated/integrated.component';
import {ReadyComponent} from './pages/ready/ready.component';

/**
 * PM (19.06.2019)
 * @see https://angular.io/guide/router
 *      https://medium.com/@christo8989/angular-6-url-parameters-860db789db85
 *      https://medium.com/developing-an-angular-4-web-app/adding-routes-for-login-and-home-view-components-be676d786471
 */
const routes: Routes = [
  {path: '', component: HomeComponent, pathMatch: 'full'}, /*the default component*/
  {path: 'integrated', component: IntegratedComponent},
  {path: 'ready', component: ReadyComponent},
  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
